import { UIEventHandler, useState } from "react";
import MediaList from "./media-list";
import MediaUpload from "./media-upload";
import { TMediaTab, TSelectMode } from "src/models/media.model";
import { useAppDispatch } from "src/config/store";
import ModalHeader from "../modal/modal-header";
import ModalBody from "../modal/modal-body";
import { closeModal } from "@/reducers/modal-reducer";

type TMediaManagerProps = {
  onSelectImage?: (id: number[]) => void;
  selectMode?: TSelectMode;
  showList?: boolean;
};

export const MediaManager = (props: TMediaManagerProps) => {
  const { onSelectImage, selectMode, showList = true } = props;

  const dispatch = useAppDispatch();
  const [tab, setTab] = useState<TMediaTab>(showList ? "LIST" : "UPLOAD");

  const onUploadSuccess = () => {
    if (showList) {
      setTab("LIST");
    } else {
      dispatch(closeModal());
    }
  };

  return (
    <>
      <ModalHeader />
      <ModalBody>
        <div className="Media-manager">
          {showList && (
            <div className="Media-manager-filter">
              <div className={`item ${tab === "LIST" ? "selected" : ""}`} onClick={() => setTab("LIST")}>
                Lista
              </div>
              <div className={`item ${tab === "UPLOAD" ? "selected" : ""}`} onClick={() => setTab("UPLOAD")}>
                Carica
              </div>
            </div>
          )}

          <div className="Media-manager-content">
            {tab === "LIST" && onSelectImage && selectMode && (
              <MediaList onSelectImage={onSelectImage} selectMode={selectMode} />
            )}
            {tab === "UPLOAD" && <MediaUpload onUploadSuccess={onUploadSuccess} />}
          </div>
        </div>
      </ModalBody>
    </>
  );
};
