import { Button } from "@/components/button";
import { Icon } from "@/components/icon";
import { Text } from "@/components/text";
import { IObject } from "@/models/types";
import { openModal } from "@/reducers/modal-reducer";
import { useAppDispatch } from "src/config/store";
import ObjectEdit from "./object-edit";
import { DeleteModal } from "@/components/delete-modal/delete-modal";
import { deleteObject } from "@/reducers/object-crud.reducer";

type Props = {
  object: IObject;
  className?: string;
};

const ObjectCard = (props: Props) => {
  const { object, className } = props;

  const dispatch = useAppDispatch();

  const handleEdit = () => {
    dispatch(
      openModal({
        element: <ObjectEdit object={object} />,
        opt: { size: "xs", title: "Modifica oggetto" },
      })
    );
  };

  const handleDelete = () => {
    dispatch(
      openModal({
        element: (
          <DeleteModal
            message={`Confermi di voler eliminare l'oggetto ${object.post_title}?`}
            onConfirm={onDeleteConfirm}
            stateKey={"objectsCrud"}
          />
        ),
        opt: { size: "xs", title: "Elimina oggetto" },
      })
    );
  };

  const onDeleteConfirm = () => {
    dispatch(deleteObject(object.ID));
  };

  return (
    <tr className={`table-item ${className || ""} `}>
      <td>{object.post_title}</td>
      <td>{object.createdAt && <Text type="date" format="DATETIME" content={object.createdAt} />}</td>
      <td>
        <div className="button-group justify-end">
          <Button size="sm" color="white" onClick={handleEdit}>
            <Icon content="edit" />
          </Button>
          <Button size="sm" color="white" onClick={handleDelete}>
            <Icon content="bin" />
          </Button>
        </div>
      </td>
    </tr>
  );
};

export default ObjectCard;
