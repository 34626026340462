import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios, { AxiosResponse } from "axios";
import { SERVER_API_URL } from "src/config/constants";
import { ICatalogItem } from "src/models/catalog.model";

const INITIAL_STATE = {
  loading: false,
  errorMessage: null as unknown as string,
  fetchSuccess: false,
  fetchFailure: false,
  item: null as unknown as ICatalogItem,
};

export type CatalogItemState = Readonly<typeof INITIAL_STATE>;

export const getCatalogItem = createAsyncThunk(
  "catalog/get-item",
  async (id: number, { rejectWithValue }) => {
    try {
      const response: AxiosResponse<ICatalogItem> = await axios.get(`${SERVER_API_URL}/api/catalog/${id}`);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

const catalogItemSlice = createSlice({
  name: "post",
  initialState: INITIAL_STATE,
  reducers: {
    clearCatalogItem() {
      return INITIAL_STATE;
    },
    setCatalogItem(state, action: PayloadAction<ICatalogItem>) {
      return {
        ...state,
        item: action.payload,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCatalogItem.pending, (state) => {
        return {
          ...state,
          loading: true,
        };
      })
      .addCase(getCatalogItem.fulfilled, (state, { payload }) => {
        return {
          ...state,
          loading: false,
          fetchSuccess: true,
          item: payload,
        };
      })
      .addCase(getCatalogItem.rejected, (state) => {
        return {
          ...state,
          loading: false,
          fetchFailure: true,
        };
      });
  },
});

const { actions } = catalogItemSlice;
export const { clearCatalogItem, setCatalogItem } = actions;
export default catalogItemSlice.reducer;
