import { useState, useEffect } from "react";
import { v4 as uuidv4 } from "uuid";
import { BibliographyCard } from "./bibliography-card";
import { IBibliography } from "@/models/catalog.model";
import { FormGroup } from "@/components/form";
import { Flex } from "@/components/flex";
import { Heading } from "@/components/heading";
import { Button } from "@/components/button";
import { Icon } from "@/components/icon";

type Props = {
  biographies: IBibliography[];
  onUpdatebibliography: (bibliography: IBibliography[]) => void;
};

export const Bibliography = (props: Props) => {
  const [bibliography, setbibliography] = useState<IBibliography[]>([]);

  const onBibliographyAdd = () => {
    const Bibliography = {
      content: "",
      id: uuidv4(),
    };

    setbibliography([Bibliography, ...bibliography]);
  };

  const onBibliographySave = (data, index) => {
    const arr = bibliography.filter((Bibliography) => Bibliography.id !== data.id);
    arr.splice(index, 0, data);
    setbibliography(arr);
    props.onUpdatebibliography(arr);
  };

  const onBibliographyDelete = (id) => {
    const arr = bibliography.filter((Bibliography) => Bibliography.id !== id);
    setbibliography(arr);
    props.onUpdatebibliography(arr);
  };

  useEffect(() => {
    setbibliography(props.biographies);
  }, [props.biographies]);

  return (
    <FormGroup>
      <Flex container alignItems="center">
        <Heading content={"Bibliografia"} size={12} />
        <Button size="sm" color="white" className="ml-auto" onClick={onBibliographyAdd}>
          <Icon content="add" />
        </Button>
      </Flex>

      {(!bibliography || bibliography?.length === 0) && "Nessuna bibliografia inserita"}

      {bibliography?.map((bibliography, index) => (
        <BibliographyCard
          key={bibliography.id}
          bibliography={bibliography}
          index={index}
          onBibliographySave={onBibliographySave}
          onBibliographyDelete={onBibliographyDelete}
        />
      ))}
    </FormGroup>
  );
};
