import styles from "./modal.module.scss";
import { closeModal } from "@/reducers/modal-reducer";
import { Button } from "../button";
import { useAppDispatch, useAppSelector } from "src/config/store";
import { Icon } from "@/components/icon";

type Props = {
  title?: string;
};

const ModalHeader = (props: Props) => {
  const { title } = props;

  const modalState = useAppSelector((state) => state.modal);

  const dispatch = useAppDispatch();

  return (
    <div className={styles.modalHeader}>
      {title || (modalState.opt.title && <h2>{title || modalState.opt.title}</h2>)}
      <div className={styles.actions}>
        <Button onClick={() => dispatch(closeModal())}>
          <Icon content="close" />
        </Button>
      </div>
    </div>
  );
};

export default ModalHeader;

ModalHeader.displayName = "Modal.Header";
