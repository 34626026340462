import { TMediaItem } from "../media.model";
import { Form, FormItem, FormRow, ReadOnly } from "@/components/form";
import ModalBody from "@/components/modal/modal-body";
import ModalHeader from "@/components/modal/modal-header";
import { Text } from "@/components/text";

type Props = {
  media: TMediaItem;
};

export const MediaDetail = (props: Props) => {
  const { media } = props;

  return (
    <>
      <ModalHeader />
      <ModalBody>
        <div className="media-detail">
          <div className="media-image" style={{ backgroundImage: `url(${media.source_url})` }} />
          <div className="media-meta">
            <Form>
              <FormRow>
                <FormItem label="Id file">
                  <ReadOnly>
                    <Text type="text" content={media.id} />
                  </ReadOnly>
                </FormItem>
              </FormRow>
              <FormRow>
                <FormItem label="Nome file">
                  <ReadOnly>
                    <Text type="text" content={`${media.media_details.original_image || media.slug}`} />
                  </ReadOnly>
                </FormItem>
              </FormRow>
              <FormRow>
                <FormItem label="Data creazione">
                  <ReadOnly>
                    <Text type="date" format="APP_DATE_FORMAT" content={media.date} />
                  </ReadOnly>
                </FormItem>
              </FormRow>
              <FormRow>
                <FormItem label="Dimensioni">
                  <ReadOnly>
                    <Text
                      type="text"
                      content={`${media.media_details.width} x ${media.media_details.width} px`}
                    />
                  </ReadOnly>
                </FormItem>
              </FormRow>
            </Form>
          </div>
        </div>
      </ModalBody>
    </>
  );
};
