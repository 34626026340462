import { useState, useEffect, useRef } from "react";

import "./select.scss";
import { Icon } from "../icon";
import { IOption } from "src/models/types";
import { Flex } from "../flex";
import { Button } from "../button";

type SelectType = {
  className?: string;
  options: IOption[];
  selected?: string | number | boolean | null;
  onSelect: (option) => void;
  placeholder?: string;
  showReset?: boolean;
  icon?: string;
  showSearch?: boolean;
  addCallback?: () => void;
};

export const Select = (props: SelectType) => {
  const { className, selected, onSelect, placeholder, showReset = true, showSearch, addCallback } = props;

  const [selectState, setSelectState] = useState({
    isSelectOpen: false,
    currentValue: selected,
  });

  const [options, setOptions] = useState<IOption[]>(props.options);

  const select = useRef<HTMLDivElement>(null);

  const handleOptionClick = (option: IOption) => {
    setSelectState({ isSelectOpen: false, currentValue: option.value });
    onSelect(option);
    setOptions(props.options);
  };

  const clearValue = () => {
    setSelectState({ ...selectState, currentValue: "" });
    onSelect("");
  };

  const toggleSelect = () => {
    setSelectState({ ...selectState, isSelectOpen: !selectState.isSelectOpen });
  };

  const handleClickOutside = (event) => {
    if (select.current && !select.current.contains(event.target)) {
      setSelectState({ ...selectState, isSelectOpen: false });
    }
  };

  const onFilterOption = (event) => {
    const key = event.target.value;
    const filtered = props.options.filter((item) => item.label.toLowerCase().includes(key.toLowerCase()));
    setOptions(filtered);
  };

  useEffect(() => {
    setSelectState({ ...selectState, currentValue: props.selected });
  }, [props.selected]);

  useEffect(() => {
    window.addEventListener("mousedown", handleClickOutside);

    return () => {
      window.removeEventListener("mousedown", handleClickOutside);
    };
  }, [handleClickOutside]);

  useEffect(() => {
    setOptions(props.options);
  }, [props.options]);

  const OptionContainer = () => {
    return (
      <div className="Select-options">
        {showSearch && (
          <div className="Select-top ">
            <div className="Select-search">
              <input type="text" placeholder="Cerca..." onInput={onFilterOption} defaultValue="" />
            </div>
            {addCallback && (
              <Button color="white" size="sm" onClick={addCallback}>
                <Icon content="add" />
              </Button>
            )}
          </div>
        )}
        <div className="Select-options-container">
          {options.length === 0 && <div className="noResult">Nessun risultato</div>}
          {options.map((option, i) => (
            <div
              className={`Select-option ${option.value === selectState.currentValue ? "active" : ""}`}
              onClick={() => handleOptionClick(option)}
              key={i}
            >
              {option.label}
            </div>
          ))}
        </div>
      </div>
    );
  };

  const getLabelByValue = (value) => {
    if (value) {
      return options.filter((option) => option.value === value)[0]?.label;
    }
    return "";
  };

  return (
    <div className={`Select ${className || ""}`} ref={select}>
      <input
        readOnly
        className="Select-input"
        onClick={toggleSelect}
        placeholder={placeholder}
        value={getLabelByValue(selectState.currentValue)}
      />
      {showReset && selectState.currentValue && (
        <div className="Select-clear" onClick={clearValue}>
          <Icon content="close" />
        </div>
      )}
      {(!showReset || !selectState.currentValue) && (
        <div className="Select-arrow" onClick={clearValue}>
          <Icon content="arrowDown" />
        </div>
      )}

      {selectState.isSelectOpen && OptionContainer()}
    </div>
  );
};
