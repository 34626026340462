import { Button } from "@/components/button";
import { Dropdown, DropdownContainer, DropdownItem, DropdownToogle } from "@/components/dropdown";
import FeaturedImage from "@/components/featured-image";
import { Flex } from "@/components/flex";
import { Form, FormGroup, FormGroupTitle, FormItem, FormRow, ReadOnly } from "@/components/form";
import { Icon } from "@/components/icon";
import { PrivateRoute } from "@/layouts/private-route";
import { clearCatalogItem, getCatalogItem } from "@/reducers/catalog-item-reducer";
import { useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "src/config/store";
import { getAppDataParamById } from "src/utils/utils";
import { Notes } from "./notes";
import { MediaContainer } from "./media-container";
import { Loader } from "@/components/loader";
import { Bibliographies } from "./bibliography";
import { openModal } from "@/reducers/modal-reducer";
import { DeleteModal } from "@/components/delete-modal/delete-modal";
import { deleteCatalogItem } from "@/reducers/catalog-crud.reducer";

export const CatalogView = () => {
  const { id }: any = useParams();

  const dispatch = useAppDispatch();
  const catalogItem = useAppSelector((state) => state.catalogItem);
  const { data } = useAppSelector((state) => state.config);

  const navigate = useNavigate();

  const handleInfo = () => {
    console.log("info");
  };
  const handleDelete = () => {
    dispatch(
      openModal({
        element: (
          <DeleteModal
            message={`Confermi di voler eliminare la scheda ${catalogItem.item.post_title}?`}
            onConfirm={onDeleteConfirm}
            stateKey={"catalogCrud"}
          />
        ),
        opt: { size: "xs", title: "Elimina scheda" },
      })
    );
  };

  const onDeleteConfirm = () => {
    if (catalogItem.item.ID) {
      dispatch(deleteCatalogItem({ id: catalogItem.item.ID, navigate }));
    }
  };

  useEffect(() => {
    dispatch(getCatalogItem(id));
  }, []);

  useEffect(() => {
    return () => {
      dispatch(clearCatalogItem());
    };
  }, []);

  return (
    <PrivateRoute>
      <>
        {catalogItem.loading && <Loader color="#000000" />}
        {!catalogItem.loading && catalogItem.item && (
          <div className="catalog-detail">
            <div className="col-left">
              <FeaturedImage catalogItem={catalogItem.item} canEdit={false} />
            </div>

            <Form className="col-right">
              <Flex container justifyContent="flex-end" className="ml-auto">
                <Flex container justifyContent="flex-end">
                  <Button onClick={handleInfo}>
                    <Icon content="info" />
                  </Button>
                </Flex>

                <Dropdown>
                  <DropdownToogle>
                    <Button size="sm" color="white">
                      <Icon content="dotsHor" />
                    </Button>
                  </DropdownToogle>
                  <DropdownContainer>
                    <DropdownItem>
                      <Link to={`/catalog/${catalogItem.item.ID}/edit`} className="button sm white">
                        Modifica
                      </Link>
                    </DropdownItem>
                    <DropdownItem>
                      <Button size="sm" color="white" onClick={handleDelete}>
                        Elimina
                      </Button>
                    </DropdownItem>
                  </DropdownContainer>
                </Dropdown>
              </Flex>

              <FormGroup>
                <FormGroupTitle title={"Informazioni generali"} />

                <FormRow>
                  <FormItem label={"Titolo"}>
                    <ReadOnly> {catalogItem.item.post_title}</ReadOnly>
                  </FormItem>
                </FormRow>
                <FormRow>
                  <FormItem label={"Oggetto"}>
                    <ReadOnly> {getAppDataParamById(data.objects, catalogItem.item.object)}</ReadOnly>
                  </FormItem>
                </FormRow>
                <FormRow>
                  <FormItem label={"Autore"}>
                    <ReadOnly> {getAppDataParamById(data.authors, catalogItem.item.author)}</ReadOnly>
                  </FormItem>
                </FormRow>
                <FormRow>
                  <FormItem label={"Collocazione"}>
                    <ReadOnly> {getAppDataParamById(data.locations, catalogItem.item.location)}</ReadOnly>
                  </FormItem>
                </FormRow>
                <FormRow>
                  <FormItem label={"Datazione"}>
                    <ReadOnly> {catalogItem.item.date}</ReadOnly>
                  </FormItem>
                </FormRow>
              </FormGroup>

              <FormGroup>
                <FormGroupTitle title={"Informazioni tecniche"} />

                <FormRow>
                  <FormItem label={"Materiale"}>
                    <ReadOnly> {getAppDataParamById(data.materials, catalogItem.item.material)}</ReadOnly>
                  </FormItem>
                </FormRow>
                <FormRow>
                  <FormItem label={"Tecnica"}>
                    <ReadOnly> {getAppDataParamById(data.technics, catalogItem.item.technic)}</ReadOnly>
                  </FormItem>
                </FormRow>
                <FormRow>
                  <FormItem label={"Misure"}>
                    <ReadOnly> {catalogItem.item.measures}</ReadOnly>
                  </FormItem>
                </FormRow>
              </FormGroup>

              <FormGroup>
                <FormGroupTitle title={"Informazioni storico critiche"} />
                <FormRow>
                  <FormItem name="content" label={"Contenuto"}>
                    <div
                      className={`readOnly`}
                      suppressContentEditableWarning={true}
                      dangerouslySetInnerHTML={{ __html: catalogItem.item.content }}
                    />
                  </FormItem>
                </FormRow>
              </FormGroup>

              <Notes notes={catalogItem.item.notes} />
              <Bibliographies bibliographies={catalogItem.item.bibliography} />
              <MediaContainer title="Gallery" data={catalogItem.item.gallery} />
              <MediaContainer title="Documents" data={catalogItem.item.documents} />
            </Form>
          </div>
        )}
      </>
    </PrivateRoute>
  );
};
