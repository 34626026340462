import { useCallback, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "src/config/store";
import { uploadMedia, uploadMediaReset } from "@/reducers/media-upload-reducer";
import { Loader } from "../loader";
import { useDropzone } from "react-dropzone";
import { TUploadState, defaultUploadState, validateFile } from "src/utils/upload-media.utils";
import cx from "classnames";

type TMediaUpload = {
  onUploadSuccess: () => void;
};

const MediaUpload = (props: TMediaUpload) => {
  const { onUploadSuccess } = props;

  const [uploadState, setUploadState] = useState<TUploadState>(defaultUploadState);

  const dispatch = useAppDispatch();
  const mediaUploadState = useAppSelector((state) => state.mediaUpload);

  const onSave = () => {
    const formData = new FormData();
    formData.append("file", uploadState.file);

    dispatch(uploadMedia(formData));
  };

  const onDrop = useCallback((dropped: File[]) => {
    if (dropped.length > 1) {
      setUploadState({
        ...uploadState,
        fileError: {
          error: "FILE_LENGTH",
        },
      });
      return;
    }

    const { isValid, error, meta } = validateFile({
      file: dropped[0],
      size: 10,
      type: ["jpg", "png", "JPG"],
      mediaType: "image",
    });

    if (!isValid) {
      setUploadState({
        ...uploadState,
        fileError: {
          error,
          meta,
        },
      });
      return;
    }

    setUploadState({
      ...uploadState,
      file: dropped[0],
      fileError: null,
    });

    // eslint-disable-next-line
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const uploadArea = cx("mediaManager-upload-container", { ["active"]: isDragActive });

  useEffect(() => {
    onSave();
  }, [uploadState.file]);

  useEffect(() => {
    if (mediaUploadState.fetchSuccess) {
      onUploadSuccess();
      dispatch(uploadMediaReset());
    }
  }, [mediaUploadState.fetchSuccess]);

  return (
    <div {...getRootProps()} className={uploadArea}>
      <div className="button-upload">
        <input {...getInputProps()} />

        {!mediaUploadState.loading ? (
          <label className="button primary md" htmlFor="avatar-upload-button">
            {"Seleziona media"}
          </label>
        ) : (
          <div>
            <Loader color="#000000" />
          </div>
        )}

        {uploadState.fileError?.error && <div>{uploadState.fileError?.meta}</div>}
      </div>
    </div>
  );
};

export default MediaUpload;
