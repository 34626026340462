import { Button } from "@/components/button";
import { Loader } from "@/components/loader";
import { Form, FormRow } from "@/components/form";
import ModalHeader from "../modal/modal-header";
import ModalBody from "../modal/modal-body";
import { useAppDispatch, useAppSelector } from "src/config/store";
import { Flex } from "../flex";
import { closeModal } from "@/reducers/modal-reducer";

type TDeleteModal = {
  message: string;
  stateKey: string;
  onConfirm: () => void;
};

export const DeleteModal = (props: TDeleteModal) => {
  const { message, stateKey, onConfirm } = props;

  const dispatch = useAppDispatch();
  const { loading } = useAppSelector((state) => state[stateKey]);

  const handleClick = () => {
    onConfirm();
  };

  return (
    <>
      <ModalHeader />
      <ModalBody>
        <Form>
          <p>{message}</p>
          <FormRow>
            <Flex container>
              <Button size="md" color="white" onClick={() => dispatch(closeModal())}>
                Annulla
              </Button>
              <Button size="md" color="primary" onClick={handleClick} className="ml-auto">
                {!loading ? "Conferma" : <Loader />}
              </Button>
            </Flex>
          </FormRow>
        </Form>
      </ModalBody>
    </>
  );
};
