import { FormGroup, FormGroupTitle } from "@/components/form";
import { MediaCard } from "./media-card";

type Props = {
  title: string;
  data: string;
};

export const MediaContainer = (props: Props) => {
  const media = JSON.parse(props.data);

  return (
    <FormGroup>
      <FormGroupTitle title={props.title} />

      {(!media || media?.length === 0) && "Non ci sono elementi"}

      <div className="Gallery">
        {media?.map((imageId) => (
          <MediaCard key={imageId} imageId={imageId} />
        ))}
      </div>
    </FormGroup>
  );
};
