type NavTopProps = {
  children: any;
};

export const NavTop = (props: NavTopProps) => {
  const { children } = props;
  return (
    <div className={`Nav-top   `}>
      <div className="Nav-top-container"> {children} </div>{" "}
    </div>
  );
};
