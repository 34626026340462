import styles from "./modal.module.scss";

type Props = {
  children: JSX.Element | JSX.Element[];
};

const ModalFooter = (props: Props) => {
  const { children } = props;

  return <div className={styles.modalFooter}>{children}</div>;
};

export default ModalFooter;

ModalFooter.displayName = "Modal.Footer";
