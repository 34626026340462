import { useForm } from "react-hook-form";
import { Form, FormItem, FormRow, FormError } from "@/components/form";
import { Flex } from "@/components/flex";
import { Button } from "@/components/button";
import { Loader } from "@/components/loader";
import { IMaterial } from "@/models/types";
import { useAppDispatch, useAppSelector } from "src/config/store";
import { createMaterial, editMaterial } from "@/reducers/materials-crud.reducer";
import { validationSchema } from "src/utils/validation-utils";
import ModalHeader from "@/components/modal/modal-header";
import ModalBody from "@/components/modal/modal-body";

type Props = {
  material: IMaterial;
};

export const MaterialsEdit = (props: Props) => {
  const { material } = props;

  const isEdit = material.ID ? true : false;
  const dispatch = useAppDispatch();
  const materialsCrudState = useAppSelector((state) => state.materialsCrud);

  const {
    register,
    handleSubmit,

    formState: { errors, isValid },
  } = useForm({ mode: "onChange", defaultValues: material });

  const save = (data) => {
    isEdit ? dispatch(editMaterial(data)) : dispatch(createMaterial(data));
  };

  return (
    <>
      <ModalHeader />
      <ModalBody>
        <Form>
          <FormRow>
            <FormItem name="post_title" label={"Nome"}>
              <input
                id="post_title"
                placeholder={"Nome"}
                {...register("post_title", validationSchema.required)}
              />

              {errors.post_title && <FormError error={errors.post_title.message} />}
            </FormItem>
          </FormRow>

          <FormRow>
            <FormItem>
              <Flex container alignItems="center">
                <Button
                  className="ml-auto"
                  size="md"
                  color="primary"
                  disabled={!isValid || materialsCrudState.loading}
                  onClick={handleSubmit(save)}
                >
                  {materialsCrudState.loading ? <Loader /> : "Salva"}
                </Button>
              </Flex>
            </FormItem>
          </FormRow>
        </Form>
      </ModalBody>
    </>
  );
};
