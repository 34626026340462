import { MenuItem } from "./menu-item";

export const MenuPrimary = () => {
  return (
    <div className="primary-menu">
      <MenuItem icon="home" title={"Dashboard"} to="/" />
      <MenuItem icon="catalog" title={"Catalogo"} to="/catalog" />
      <MenuItem icon="authors" title={"Autori"} to="/authors" />
      <MenuItem icon="objects" title={"Oggetti"} to="/objects" />
      <MenuItem icon="location" title={"Collocazioni"} to="/locations" />
      <MenuItem icon="material" title={"Materiali"} to="/materials" />
      <MenuItem icon="technic" title={"Tecniche"} to="/technics" />
      <MenuItem icon="media" title={"Media"} to="/media" />
    </div>
  );
};
