import { useCallback, useEffect } from "react";
import styles from "./modal.module.scss";
import cx from "classnames";
import ModalContent from "./modal-content";
import ModalBackdrop from "./modal-backdrop";
import ModalHeader from "./modal-header";
import ModalBody from "./modal-body";
import ModalFooter from "./modal-footer";
import { useAppDispatch, useAppSelector } from "src/config/store";

import { closeModal } from "@/reducers/modal-reducer";

const Modal = () => {
  const dispatch = useAppDispatch();
  const modalState = useAppSelector((state) => state.modal);

  const wrapper = cx(styles.modalWrapper, {
    [styles.open]: modalState.open,
  });

  const handleKey = useCallback(
    (e) => {
      e.keyCode === 27 && dispatch(closeModal());
    },
    [dispatch]
  );

  useEffect(() => {
    window.addEventListener("keydown", handleKey);
    return () => {
      window.removeEventListener("keydown", handleKey);
    };
  }, [handleKey]);

  useEffect(() => {
    if (!modalState.open) {
      document.body.removeAttribute("style");
    } else {
      document.body.style.overflow = "hidden";
    }
  }, [modalState.open]);

  return (
    <div className={wrapper}>
      <Modal.Backdrop />
      <Modal.Content>{modalState.element}</Modal.Content>
    </div>
  );
};

Modal.Content = ModalContent;
Modal.Backdrop = ModalBackdrop;
Modal.Header = ModalHeader;
Modal.Body = ModalBody;
Modal.Footer = ModalFooter;

export default Modal;
