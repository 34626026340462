import { FormItem, ReadOnly } from "@/components/form";
import { IBibliography } from "src/models/catalog.model";

type Props = {
  bibliography: IBibliography;
};

export const BibliographyCard = (props: Props) => {
  const { bibliography } = props;

  return (
    <FormItem>
      <ReadOnly> {bibliography.content}</ReadOnly>
    </FormItem>
  );
};
