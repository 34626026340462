import { useState, useEffect } from "react";
import { MediaCard } from "./media-card";
import { TMediaType } from "@/models/media.model";
import { FormGroup, FormGroupTitle } from "@/components/form";
import { Flex } from "@/components/flex";
import { Button } from "@/components/button";
import { Icon } from "@/components/icon";
import { useAppDispatch } from "src/config/store";
import { closeModal, openModal } from "@/reducers/modal-reducer";
import { MediaManager } from "@/components/media-manager";

type Props = {
  title: string;
  data: number[];
  mediaType: TMediaType;
  onUpdate: (data: number[]) => void;
};

export const MediaContainer = (props: Props) => {
  const [media, setMedia] = useState<number[]>([]);

  const dispatch = useAppDispatch();

  const onSelectImage = (ids: number[]) => {
    const updated = [...new Set([...ids, ...media])];
    setMedia(updated);
    props.onUpdate(updated);

    dispatch(closeModal());
  };

  const onRemoveImage = (id: number) => {
    const updated = media?.filter((item) => item !== id);
    if (updated) {
      setMedia(updated);
      props.onUpdate(updated);
    }
  };

  const handleMediaManager = () => {
    dispatch(
      openModal({
        element: <MediaManager onSelectImage={onSelectImage} selectMode="MULTIPLE" />,
        opt: { size: "md", title: "Media manager" },
      })
    );
  };

  useEffect(() => {
    setMedia(props.data);
  }, [props.data]);

  return (
    <FormGroup>
      <Flex container alignItems="center">
        <FormGroupTitle title={props.title} />
        <Button size="sm" color="white" className="ml-auto" onClick={handleMediaManager}>
          <Icon content="add" />
        </Button>
      </Flex>
      {(!media || media?.length === 0) && "Nessun elemento selezionato"}
      <div className="Gallery">
        {media?.map((imageId) => (
          <MediaCard key={imageId} imageId={imageId} onRemoveImage={onRemoveImage} />
        ))}
      </div>
    </FormGroup>
  );
};
