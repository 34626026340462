import cx from "classnames";

type Props = {
  title: string;
  children?: any;
  className?: string;
};

export const PageTopbar = (props: Props) => {
  const { title, children, className } = props;

  const classNames = cx("Page-header", className);

  return (
    <div className={classNames}>
      <h1> {title} </h1>
      {children}
    </div>
  );
};
