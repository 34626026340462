import { useState, useEffect } from "react";
import { MediaCard } from "./media-card";
import { Button } from "@/components/button";
import { Loader } from "@/components/loader";
import { PageTopbar } from "@/components/page-topbar";
import { useAppDispatch, useAppSelector } from "src/config/store";
import { getAllMedia } from "@/reducers/media-reducer";
import { openModal } from "@/reducers/modal-reducer";
import { MediaManager } from "@/components/media-manager";
import { defaultMediaParams } from "src/utils/media-utils";
import { IMediaParams } from "@/models/media.model";
import { PrivateRoute } from "@/layouts/private-route";
import { getQueryParamsFromUrl, setParamsToUrl } from "src/utils/search-utils";
import { useLocation, useNavigate } from "react-router-dom";
import { TableListPagination } from "@/components/table-list";

export const Media = () => {
  const dispatch = useAppDispatch();
  const mediaState = useAppSelector((state) => state.media);

  const location = useLocation();
  const navigate = useNavigate();

  const [params, setParams] = useState<IMediaParams>(getQueryParamsFromUrl(defaultMediaParams, location));

  const handleMediaManager = () => {
    dispatch(
      openModal({
        element: <MediaManager showList={false} />,
        opt: { size: "xs", title: "Media manager" },
      })
    );
  };

  const onChangePage = (page: number) =>
    setParams({
      ...params,
      page,
    });

  useEffect(() => {
    dispatch(getAllMedia(params));
    setParamsToUrl(params, navigate);
  }, [params.page, params.postPerPage]);

  useEffect(() => {
    setParams(getQueryParamsFromUrl(defaultMediaParams, location.search));
  }, [location.search]);

  return (
    <PrivateRoute>
      <>
        <PageTopbar title={"Media"}>
          <div className="button-group ml-auto">
            <Button className="button md solid primary" onClick={handleMediaManager}>
              Nuovo media
            </Button>
          </div>
        </PageTopbar>

        {mediaState.loading && <Loader color="#000000" />}
        {!mediaState.loading && mediaState.search?.length === 0 && "Nessun media"}
        {mediaState.search?.length > 0 && (
          <div className="media-grid">
            {mediaState.search?.map((media) => {
              return <MediaCard key={media.id} media={media} />;
            })}
          </div>
        )}
        <TableListPagination
          activePage={params.page}
          paginate={onChangePage}
          itemsPerPage={params.postPerPage}
          totalItems={mediaState.count}
        />
      </>
    </PrivateRoute>
  );
};
