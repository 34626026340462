import styles from "./modal.module.scss";

type Props = {
  children: JSX.Element | JSX.Element[];
};

const ModalBody = (props: Props) => {
  const { children } = props;

  return <div className={styles.modalBody}>{children}</div>;
};

export default ModalBody;

ModalBody.displayName = "Modal.Body";
