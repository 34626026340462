import { Button } from "@/components/button";
import { Flex } from "@/components/flex";
import { Form, FormItem, FormRow } from "@/components/form";
import { Icon } from "@/components/icon";
import { INote } from "@/models/catalog.model";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { validationSchema } from "src/utils/validation-utils";

type Props = {
  note: INote;
  index: number;
  onNoteSave: (note: INote, index: number) => void;
  onNoteDelete: (id: string) => void;
};

export const NoteCard = (props: Props) => {
  const { note, index, onNoteSave, onNoteDelete } = props;
  const [isEdit, setIsEdit] = useState<boolean>(!note.content ? true : false);

  const defaultNote = {
    id: note.id,
    content: "",
  };

  const {
    register,
    handleSubmit,
    getValues,
    reset,
    formState: { isValid },
  } = useForm({ mode: "onChange", defaultValues: defaultNote });

  const onSave = (data) => {
    onNoteSave(data, index);
    setIsEdit(false);
  };

  const onCancel = () => {
    if (!getValues("content")) {
      onNoteDelete(getValues("id"));
    } else {
      setIsEdit(false);
    }
  };

  const onEdit = () => {
    reset(note);
    setIsEdit(true);
  };

  return (
    <div className="NoteCard">
      {isEdit && (
        <Form>
          <FormRow>
            <FormItem>
              <textarea
                id="post_title"
                className="textarea"
                placeholder={"Inserisci nota"}
                {...register("content", validationSchema.required)}
                defaultValue={getValues("content")}
              />
            </FormItem>
          </FormRow>

          <FormRow>
            <FormItem>
              <Flex container alignItems="center">
                <Button size="md" onClick={onCancel}>
                  {"Annulla"}
                </Button>

                <Button
                  className="ml-auto"
                  size="md"
                  color="primary"
                  disabled={!isValid}
                  onClick={handleSubmit(onSave)}
                >
                  {"Salva"}
                </Button>
              </Flex>
            </FormItem>
          </FormRow>
        </Form>
      )}

      {!isEdit && (
        <Flex container>
          {note.content}

          <div className="button-group ml-auto">
            <Button onClick={onEdit}>
              <Icon content="edit" />
            </Button>
            <Button onClick={() => onNoteDelete(getValues("id"))}>
              <Icon content="bin" />
            </Button>
          </div>
        </Flex>
      )}
    </div>
  );
};
