import { useAppSelector } from "src/config/store";
import cx from "classnames";

type NavSideProps = {
  children: any;
};

export const NavSide = (props: NavSideProps) => {
  const { children } = props;

  const { open } = useAppSelector((state) => state.nav);
  const className = cx("Nav-side", { ["isMenuOpen"]: open });

  return (
    <div className={className}>
      <div className="Nav-side-container">{children}</div>
    </div>
  );
};
