import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios, { AxiosResponse } from "axios";
import { SERVER_API_URL } from "src/config/constants";
import { ICatalogSearch } from "src/models/catalog.model";
import { TCatalogSearchParams } from "src/utils/catalog-utils";

const INITIAL_STATE = {
  loading: false,
  errorMessage: null as unknown as string,
  fetchSuccess: false,
  fetchFailure: false,
  search: null as unknown as ICatalogSearch,
};

export type CatalogSearchState = Readonly<typeof INITIAL_STATE>;

export const getCatalog = createAsyncThunk(
  "catalog/search",
  async (params: TCatalogSearchParams, { rejectWithValue }) => {
    try {
      const response: AxiosResponse<ICatalogSearch> = await axios.post(
        `${SERVER_API_URL}/api/catalog/search`,
        params
      );
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

const catalogSearchSlice = createSlice({
  name: "post",
  initialState: INITIAL_STATE,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getCatalog.pending, (state) => {
        return {
          ...state,
          loading: true,
        };
      })
      .addCase(getCatalog.fulfilled, (state, { payload }) => {
        return {
          ...state,
          loading: false,
          fetchSuccess: true,
          search: payload,
        };
      })
      .addCase(getCatalog.rejected, (state) => {
        return {
          ...state,
          loading: false,
          fetchFailure: true,
        };
      });
  },
});

export default catalogSearchSlice.reducer;
