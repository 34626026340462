// Define here modal keys

import { PayloadAction, createSlice } from "@reduxjs/toolkit";

type TModalOpt = {
  title?: string;
  size?: "xxs" | "xs" | "sm" | "md" | "lg" | "xl";
  canClose?: boolean;
};

const defaultOpt: TModalOpt = {
  title: "",
  size: "md",
  canClose: true,
};

type TInitialState = {
  open: boolean;
  element: JSX.Element | null;
  opt: TModalOpt;
};

type TModalPayload = {
  element: JSX.Element | null;
  opt: TModalOpt;
};

const INITIAL_STATE: TInitialState = {
  open: false,
  element: null as unknown as JSX.Element,
  opt: defaultOpt,
};

export type ModalState = Readonly<typeof INITIAL_STATE>;

const modalSlice = createSlice({
  name: "post",
  initialState: INITIAL_STATE,
  reducers: {
    openModal(state, action: PayloadAction<TModalPayload>) {
      return {
        open: true,
        element: action.payload.element,
        opt: {
          ...state.opt,
          ...action.payload.opt,
        },
      };
    },
    closeModal() {
      return INITIAL_STATE;
    },
  },
});

const { actions, reducer } = modalSlice;
export const { openModal, closeModal } = actions;
export default reducer;
