import { NavLink } from "react-router-dom";
import { ReactComponent as Logo } from "./archivio-logo.svg";

type Props = {
  className?: string;
};

export const Brand = (props: Props) => (
  <NavLink to="/" className={`brand-logo ${props.className || ""}`}>
    <div className="logo">
      <Logo />
    </div>
  </NavLink>
);
