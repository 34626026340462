import { useAppSelector } from "src/config/store";
import { Navigate } from "react-router-dom";
import { MainLayout } from "@/layouts/main-layout";

type Props = {
  children: JSX.Element;
};

export const PrivateRoute = (props: Props) => {
  const { children } = props;
  const { isAuthenticated, sessionHasBeenFetched } = useAppSelector((state) => state.auth);

  if (!sessionHasBeenFetched) {
    return <></>;
  }

  return (
    <>{isAuthenticated ? <MainLayout>{children}</MainLayout> : <Navigate to={{ pathname: "/login" }} />}</>
  );
};
