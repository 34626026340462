import { ILocation } from "@/models/types";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios, { AxiosResponse } from "axios";
import { SERVER_API_URL } from "src/config/constants";
import { closeModal } from "./modal-reducer";
import { refreshTechnicsSearch } from "./technics-search.reducer";
import { getConfig } from "./config-reducer";

const INITIAL_STATE = {
  loading: false,
  errorMessage: null as unknown as string,
  fetchSuccess: false,
  fetchFailure: false,
};

export type TechnicsCrudState = Readonly<typeof INITIAL_STATE>;

export const createTechnic = createAsyncThunk(
  "technics/create",
  async (params: ILocation, { rejectWithValue, dispatch }) => {
    try {
      const response: AxiosResponse<boolean> = await axios.post(`${SERVER_API_URL}/api/technics`, params);
      dispatch(refreshTechnicsSearch());
      dispatch(closeModal());
      dispatch(getConfig());
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const editTechnic = createAsyncThunk(
  "technics/edit",
  async (params: ILocation, { rejectWithValue, dispatch }) => {
    try {
      const response: AxiosResponse<boolean> = await axios.put(`${SERVER_API_URL}/api/technics`, params);
      dispatch(refreshTechnicsSearch());
      dispatch(closeModal());
      dispatch(getConfig());
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const deleteTechnic = createAsyncThunk(
  "technics/delete",
  async (id: number | undefined, { rejectWithValue, dispatch }) => {
    try {
      const response: AxiosResponse<boolean> = await axios.delete(`${SERVER_API_URL}/api/technics/${id}`);
      dispatch(refreshTechnicsSearch());
      dispatch(closeModal());
      dispatch(getConfig());
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

const technicsCrudSlice = createSlice({
  name: "post",
  initialState: INITIAL_STATE,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createTechnic.pending, (state) => {
        return {
          ...state,
          loading: true,
        };
      })
      .addCase(createTechnic.fulfilled, (state) => {
        return {
          ...state,
          loading: false,
          fetchSuccess: true,
        };
      })
      .addCase(createTechnic.rejected, (state) => {
        return {
          ...state,
          loading: false,
        };
      })
      .addCase(editTechnic.pending, (state) => {
        return {
          ...state,
          loading: true,
        };
      })
      .addCase(editTechnic.fulfilled, (state) => {
        return {
          ...state,
          loading: false,
          fetchSuccess: true,
        };
      })
      .addCase(editTechnic.rejected, (state) => {
        return {
          ...state,
          loading: false,
        };
      })
      .addCase(deleteTechnic.pending, (state) => {
        return {
          ...state,
          loading: true,
        };
      })
      .addCase(deleteTechnic.fulfilled, (state) => {
        return {
          ...state,
          loading: false,
          fetchSuccess: true,
        };
      })
      .addCase(deleteTechnic.rejected, (state) => {
        return {
          ...state,
          loading: false,
        };
      });
  },
});

export default technicsCrudSlice.reducer;
