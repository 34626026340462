import { useState, useRef, useEffect } from "react";

import { TDropdown, TDropdownContainer, TDropdownItem, TDropdownToggle } from "./dropdown.model";
import "./dropdown.scss";

export const Dropdown = (props: TDropdown) => {
  const { className, children } = props;
  const [isDropdownOpen, setDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setDropdownOpen(!isDropdownOpen);
  };

  const dropdown = useRef<HTMLDivElement>(null);

  function handleClickOutside(event) {
    if (dropdown.current && !dropdown.current.contains(event.target)) {
      setDropdownOpen(false);
    }
  }

  useEffect(() => {
    window.addEventListener("mousedown", handleClickOutside);

    return () => {
      window.removeEventListener("mousedown", handleClickOutside);
    };
  }, [handleClickOutside]);

  return (
    <>
      <div
        className={`dropdown ${className || ""} ${isDropdownOpen ? "open" : ""}`}
        ref={dropdown}
        onClick={() => toggleDropdown()}
      >
        {children}
      </div>
    </>
  );
};

export const DropdownToogle = (props: TDropdownToggle) => {
  const { className, children } = props;
  return <div className={` dropdown-toggle ${className}`}> {children}</div>;
};

export const DropdownContainer = (props: TDropdownContainer) => {
  const { className, children } = props;
  return <div className={` dropdown-container ${className}`}> {children}</div>;
};

export const DropdownItem = (props: TDropdownItem) => {
  const { children, onClick } = props;
  return (
    <div className="dropdown-item" onClick={onClick}>
      {children}
    </div>
  );
};
