import { IMediaParams, IValidateFile } from "src/models/media.model";

export const defaultMediaParams: IMediaParams = {
  page: 1,
  postPerPage: 12,
};

const fileTypes = {
  image: ["jpg", "jpeg", "png"],
  pdf: ["pdf"],
};

export const validateFile = (props: IValidateFile): boolean => {
  const { target, validSize, validType } = props;

  const fileType = target.name.split(".").pop();
  const fileSize = target.size / (1024 * 1024);

  if (fileType && fileSize) {
    return fileTypes[validType].includes(fileType) && fileSize <= validSize;
  } else {
    return true;
  }
};
