type TAvatar = {
  avatar?: string;
  size: "sm" | "md" | "lg" | "xl";
};

export const Avatar = (props: TAvatar) => {
  const { avatar, size } = props;

  return (
    <div className={`Avatar ${size}`}>
      <img src={avatar || "content/images/default.png"} />
    </div>
  );
};
