export type TMediaType = "image" | "video" | "file";

export type TUploadState = {
  loading: boolean;
  error: boolean;
  errorMessage: TErrorType | null;
  file: File;
  fileError: TFileError | null;
};

type TFileError = {
  error: TErrorType | null;
  meta?: any[] | null;
};

export const defaultUploadState: TUploadState = {
  loading: false,
  error: false,
  errorMessage: null,
  file: null as unknown as File,
  fileError: null,
};

type TErrorType = "FILE_TYPE_NOT_VALID" | "FILE_SIZE_NOT_VALID" | "FILE_NOT_VALID" | "FILE_LENGTH";

type TValidateFile = {
  file: File;
  size?: number;
  type?: string[];
  mediaType: TMediaType;
};
type TValidationResponse = {
  isValid: boolean;
  error: TErrorType | null;
  meta: any[] | null;
};

export const validateFile = (props: TValidateFile): TValidationResponse => {
  const { file, size, type, mediaType } = props;

  const fileType = file.name.split(".").pop()?.toLowerCase();
  const fileSize = file.size / (1024 * 1024);
  const fileMediaType = file.type.split("/").shift();

  if (
    ((mediaType === "image" || mediaType === "video") && fileMediaType !== mediaType) ||
    (mediaType === "file" && (fileMediaType === "image" || fileMediaType === "video"))
  ) {
    return { isValid: false, error: "FILE_NOT_VALID", meta: null };
  }

  if (type && fileType && !type.includes(fileType)) {
    return { isValid: false, error: "FILE_TYPE_NOT_VALID", meta: [type] };
  }

  if (size && fileSize > size) {
    return { isValid: false, error: "FILE_SIZE_NOT_VALID", meta: [size] };
  }

  return { isValid: true, error: null, meta: null };
};

export const getFileType = (file) => {
  const type = file.type.split("/")[0];
  return type === "image" || type === "video" ? type : "file";
};

/**
 * Format bytes as human-readable text.
 *
 * @param bytes Number of bytes.
 * @param si True to use metric (SI) units, aka powers of 1000. False to use
 *           binary (IEC), aka powers of 1024.
 * @param dp Number of decimal places to display.
 *
 * @return Formatted string.
 */

export const humanFileSize = (bytes, si = false, dp = 1) => {
  const thresh = si ? 1000 : 1024;

  if (Math.abs(bytes) < thresh) {
    return bytes + " B";
  }

  const units = si
    ? ["kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
    : ["KiB", "MiB", "GiB", "TiB", "PiB", "EiB", "ZiB", "YiB"];
  let u = -1;
  const r = 10 ** dp;

  do {
    bytes /= thresh;
    ++u;
  } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);

  return bytes.toFixed(dp) + " " + units[u];
};

export type TFormattedFileType = "AVATAR" | "COVER" | "CARD" | "BLOCK";
export type TFormattedFileAction = "SCALE";

export type TCreateMediaImageOptions = {
  type: TFormattedFileType;
  actions: TFormattedFileAction[];
};
