import styles from "./modal.module.scss";
import cx from "classnames";
import { useAppSelector } from "src/config/store";

type Props = {
  children: JSX.Element | null;
};

const ModalContent = (props: Props) => {
  const { children } = props;

  const modalState = useAppSelector((state) => state.modal);

  const modal = cx(styles.modal, modalState.opt.size ? styles[modalState.opt.size] : "");

  return <div className={modal}>{children}</div>;
};

export default ModalContent;

ModalContent.displayName = "Modal.Content";
