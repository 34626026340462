import { Button } from "@/components/button";
import { PageTopbar } from "@/components/page-topbar";
import { PrivateRoute } from "@/layouts/private-route";
import { useCallback, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "src/config/store";
import {
  ICatalogParams,
  TCatalogSort,
  catalogSearchTableHeader,
  defaultCatalogSearchParams,
} from "src/utils/catalog-utils";
import { getQueryParamsFromUrl, setParamsToUrl } from "src/utils/search-utils";
import { CatalogFilters } from "./catalog-filters";
import { getCatalog } from "@/reducers/catalog-search-reducer";
import { Loader } from "@/components/loader";
import CatalogCard from "./catalog-card";
import { TableList, TableListBody, TableListHeader, TableListPagination } from "@/components/table-list";

export const CatalogSearch = () => {
  const { data } = useAppSelector((state) => state.config);
  const catalog = useAppSelector((state) => state.catalogSearch);
  const dispatch = useAppDispatch();

  const location = useLocation();
  const navigate = useNavigate();

  const [showFilters, toggleFilters] = useState<boolean>(false);
  const [params, setParams] = useState<ICatalogParams>(
    getQueryParamsFromUrl(defaultCatalogSearchParams, location.search)
  );

  const onRemoveParams = (key: string) => {
    setParams({
      ...params,
      [key]: null,
    });
  };
  const onChangeParams = (p: ICatalogParams) => {
    setParams(p);
  };

  const onToggleFilters = () => {
    toggleFilters(!showFilters);
  };

  const onChangePage = (page: number) =>
    setParams({
      ...params,
      offset: page * params.size,
    });

  const onChangeSort = (sort: TCatalogSort) => () =>
    setParams({
      ...params,
      orderBy: sort,
      order: params.order === "ASC" ? "DESC" : "ASC",
    });

  const currentPage = useCallback(() => {
    return Math.floor(params.offset / params.size);
  }, [params.offset]);

  useEffect(() => {
    dispatch(getCatalog(params));
    setParamsToUrl(params, navigate);
  }, [
    params.author,
    params.date,
    params.location,
    params.material,
    params.object,
    params.technic,
    params.term,
    params.orderBy,
    params.order,
    params.offset,
  ]);

  useEffect(() => {
    setParams(getQueryParamsFromUrl(defaultCatalogSearchParams, location.search));
  }, [location.search]);

  return (
    <PrivateRoute>
      <>
        <PageTopbar title={"Catalogo"}>
          <div className="button-group ml-auto">
            <Button color="white" size="md" onClick={onToggleFilters}>
              Filtri
            </Button>

            <Link className="button md solid primary" to="/catalog/new">
              Nuova scheda
            </Link>
          </div>
        </PageTopbar>

        {data && (
          <CatalogFilters
            showFilters={showFilters}
            toggleFilters={onToggleFilters}
            params={params}
            onRemove={onRemoveParams}
            onChange={onChangeParams}
          />
        )}

        {catalog.loading && <Loader color="#000000" />}
        {!catalog.loading && catalog.fetchSuccess && (
          <>
            {catalog.search?.list?.length === 0 && "Nessun risultato"}
            {catalog.search?.list?.length > 0 && (
              <>
                <TableList className="color-black">
                  <TableListHeader
                    items={catalogSearchTableHeader(onChangeSort)}
                    sortBy={params.orderBy}
                    sortDirection={params.order}
                  />
                  <TableListBody>
                    {catalog.search?.list?.map((item) => (
                      <CatalogCard key={item.ID} item={item} />
                    ))}
                  </TableListBody>
                </TableList>
                <TableListPagination
                  activePage={currentPage()}
                  paginate={onChangePage}
                  itemsPerPage={params.size}
                  totalItems={catalog.search.count}
                />
              </>
            )}
          </>
        )}
      </>
    </PrivateRoute>
  );
};
