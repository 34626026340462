import { useEffect, useState } from "react";
import axios from "axios";
import { ICatalogItem } from "@/models/catalog.model";
import { SERVER_API_URL } from "src/config/constants";
import { Icon } from "@/components/icon";
import { useAppDispatch } from "src/config/store";
import { closeModal, openModal } from "@/reducers/modal-reducer";
import { MediaManager } from "@/components/media-manager";

type Props = {
  catalogItem: ICatalogItem;
  onUpdateMedia: (mediaId?: number) => void;
  canEdit?: boolean;
};

export const FeaturedImage = (props: Props) => {
  const { catalogItem, onUpdateMedia, canEdit = true } = props;

  const dispatch = useAppDispatch();

  const [imageUrl, setImageUrl] = useState<string>("");

  const getImageByID = async (id: number) => {
    try {
      const result = await axios.get(`${SERVER_API_URL}/wp/v2/media/${id}`);
      setImageUrl(result.data.source_url);
      dispatch(closeModal());
    } catch (error) {
      console.log(error);
    }
  };

  const onSelectImage = (ids: number[]) => {
    getImageByID(ids[0]);
    onUpdateMedia(ids[0]);
  };

  const onRemoveMedia = () => {
    onUpdateMedia();
    setImageUrl("");
  };

  const handleMediaManager = () => {
    dispatch(
      openModal({
        element: <MediaManager onSelectImage={onSelectImage} selectMode="SINGLE" />,
        opt: { size: "md", title: "Media manager" },
      })
    );
  };

  useEffect(() => {
    catalogItem?.featured_media && getImageByID(catalogItem?.featured_media);
  }, [catalogItem?.featured_media]);

  return (
    <>
      <div className="featured-container">
        {imageUrl && canEdit && (
          <div className="button sm white remove-featured-image" onClick={() => onRemoveMedia()}>
            <Icon content="bin" />
          </div>
        )}

        <div className="featured-image" style={{ backgroundImage: `url(${imageUrl})` }} />

        {canEdit && (
          <button className="button md primary full-width" onClick={handleMediaManager}>
            {imageUrl ? "Cambia immagine" : "Seleziona immagine"}
          </button>
        )}
      </div>
    </>
  );
};
