import { useState, useEffect } from "react";

import { useLocation, useNavigate } from "react-router-dom";

import AuthorsCard from "./authors-card";
import {
  TableList,
  TableListBody,
  TableListHeader,
  TableListPagination,
  TTableListHeaderItem,
} from "@/components/table-list";

import { Loader } from "@/components/loader";
import { IdefaultSearchParams, TOrderBy } from "@/models/types";
import { defaultSearchParams, getQueryParamsFromUrl, setParamsToUrl } from "src/utils/search-utils";
import { getAuthors } from "@/reducers/authors-search.reducer";
import { useAppDispatch, useAppSelector } from "src/config/store";
import { PrivateRoute } from "@/layouts/private-route";
import { PageTopbar } from "@/components/page-topbar";
import { Button } from "@/components/button";
import { openModal } from "@/reducers/modal-reducer";
import AuthorsEdit from "./authors-edit";
import { defaultAuthor } from "src/utils/utils";

export const AuthorsSearch = () => {
  const dispatch = useAppDispatch();
  const authorsState = useAppSelector((state) => state.authorsSearch);

  const location = useLocation();
  const navigate = useNavigate();

  const [params, setParams] = useState<IdefaultSearchParams>(
    getQueryParamsFromUrl(defaultSearchParams, location)
  );

  const onNewAuthor = () => {
    dispatch(
      openModal({
        element: <AuthorsEdit author={defaultAuthor} />,
        opt: { size: "xs", title: "Nuovo autore" },
      })
    );
  };

  const onChangePage = (page: number) =>
    setParams({
      ...params,
      offset: params.size * page,
    });

  const getCurrentPage = Math.floor(params.offset / params.size);

  const onChangeSort = (sort: TOrderBy) => () =>
    setParams({
      ...params,
      orderBy: sort,
      order: params.order === "ASC" ? "DESC" : "ASC",
    });

  const tableHeader: TTableListHeaderItem[] = [
    {
      id: "title",
      name: "Titolo",
      onClick: onChangeSort("title"),
    },
    {
      id: "createdDate",
      name: "Data creazione",
      onClick: onChangeSort("createdDate"),
    },
    {},
  ];

  useEffect(() => {
    dispatch(getAuthors(params));
    setParamsToUrl(params, navigate);
  }, [params.orderBy, params.order, params.offset, params.size, authorsState.refresh]);

  useEffect(() => {
    setParams(getQueryParamsFromUrl(defaultSearchParams, location.search));
  }, [location.search]);

  return (
    <PrivateRoute>
      <>
        <PageTopbar title={"Autori"}>
          <div className="button-group ml-auto">
            <Button className="button md solid primary" onClick={onNewAuthor}>
              Nuovo autore
            </Button>
          </div>
        </PageTopbar>

        {authorsState.loading && <Loader color="#000000" />}
        {authorsState.fetchFailure && "Errore caricamento dati"}
        {!authorsState.loading && authorsState.search?.length === 0 && "Nessun dato"}

        {authorsState.search?.length > 0 && (
          <>
            <TableList className="color-black">
              <TableListHeader items={tableHeader} sortBy={params.orderBy} sortDirection={params.order} />
              <TableListBody>
                {authorsState.search?.map((author) => (
                  <AuthorsCard key={author.ID} author={author} />
                ))}
              </TableListBody>
            </TableList>
            <TableListPagination
              activePage={getCurrentPage}
              paginate={onChangePage}
              itemsPerPage={params.size}
              totalItems={authorsState.search.length}
            />
          </>
        )}
      </>
    </PrivateRoute>
  );
};
