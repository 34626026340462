import { FormGroup, FormGroupTitle, FormRow } from "@/components/form";
import { NoteCard } from "./note-card";

type Props = {
  notes: string;
};

export const Notes = (props: Props) => {
  const notes = JSON.parse(props.notes);

  return (
    <FormGroup>
      <FormGroupTitle title={"Note"} />
      <FormRow>
        {(!notes || notes?.length === 0) && "Nessuna nota inserita"}

        {notes?.map((note) => (
          <NoteCard key={note.id} note={note} />
        ))}
      </FormRow>
    </FormGroup>
  );
};
