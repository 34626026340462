import { IMaterial, ITechnic, IdefaultSearchParams } from "@/models/types";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios, { AxiosResponse } from "axios";
import { SERVER_API_URL } from "src/config/constants";

const INITIAL_STATE = {
  loading: false,
  errorMessage: null as unknown as string,
  fetchSuccess: false,
  fetchFailure: false,
  search: null as unknown as IMaterial[],
  count: null as unknown as number,
  refresh: 0,
};

export type TechnicsSearchState = Readonly<typeof INITIAL_STATE>;

export const getTechnics = createAsyncThunk(
  "technics/search",
  async (params: IdefaultSearchParams, { rejectWithValue }) => {
    try {
      const response: AxiosResponse<ITechnic[]> = await axios.post(
        `${SERVER_API_URL}/api/technics/search`,
        params
      );
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

const technicsSearchSlice = createSlice({
  name: "post",
  initialState: INITIAL_STATE,
  reducers: {
    refreshTechnicsSearch(state) {
      return {
        ...state,
        refresh: state.refresh + 1,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTechnics.pending, (state) => {
        return {
          ...state,
          loading: true,
        };
      })
      .addCase(getTechnics.fulfilled, (state, { payload }) => {
        return {
          ...state,
          loading: false,
          fetchSuccess: true,
          search: payload,
        };
      })
      .addCase(getTechnics.rejected, (state) => {
        return {
          ...state,
          loading: false,
          fetchFailure: true,
        };
      });
  },
});

const { actions } = technicsSearchSlice;
export const { refreshTechnicsSearch } = actions;
export default technicsSearchSlice.reducer;
