import { Header } from "@/components/header";

type Props = {
  children: JSX.Element;
};

export const MainLayout = (props: Props) => {
  const { children } = props;

  return (
    <div className="app-wrapper">
      <Header />
      <div className="app-content">{children}</div>
    </div>
  );
};
