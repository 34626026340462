import { createSlice } from "@reduxjs/toolkit";

const INITIAL_STATE = {
  open: false,
};

export type NavState = Readonly<typeof INITIAL_STATE>;

const navSlice = createSlice({
  name: "post",
  initialState: INITIAL_STATE,
  reducers: {
    toggleNav(state) {
      return {
        open: !state.open,
      };
    },
  },
});

const { actions, reducer } = navSlice;
export const { toggleNav } = actions;
export default reducer;
