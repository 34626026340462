import { TTableListHeaderItem } from "@/components/table-list";
import { IOption } from "src/models/types";

export interface ICatalogParams {
  term: string | null;
  date: number | null;
  author: number | null;
  object: number | null;
  location: number | null;
  material: number | null;
  technic: number | null;
  size: number;
  offset: number;
  orderBy: TCatalogSort;
  order: "ASC" | "DESC";
}

export const defaultCatalogSearchParams: ICatalogParams = {
  term: null,
  author: null,
  object: null,
  location: null,
  date: null,
  material: null,
  technic: null,
  size: 10,
  offset: 0,
  orderBy: "createdDate",
  order: "DESC",
};

export const createOptions = (data) => {
  const options: IOption[] = data?.map((item) => {
    return { value: item.ID, label: item.post_title };
  });
  return options;
};

export type TCatalogSort = "name" | "object" | "createdBy" | "createdDate" | "modifiedAt";

export interface ICatalogPagination {
  size: number;
  offset: number;
  orderBy: TCatalogSort;
  order: "ASC" | "DESC";
  modifiedBy?: number;
}

export type TCatalogSearchParams = ICatalogParams & ICatalogPagination;

export const catalogSearchTableHeader = (onChangeSort): TTableListHeaderItem[] => {
  return [
    {},

    {
      id: "name",
      name: "Titolo",
      onClick: onChangeSort("name"),
    },
    {
      name: "Oggetto",
    },
    {
      id: "createdDate",
      name: "Data creazione",
      onClick: onChangeSort("createdDate"),
    },
    {
      id: "modifiedAt",
      name: "Ultima modifica",
      onClick: onChangeSort("modifiedAt"),
    },
    {
      id: "createdBy",
      name: "Creato da",
      onClick: onChangeSort("createdBy"),
    },
    {},
  ];
};
