import { configureStore } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";

import authReducer from "@/reducers/auth-reducer";
import modalReducer from "@/reducers/modal-reducer";
import navReducer from "@/reducers/nav-reducer";
import configReducer from "@/reducers/config-reducer";
import catalogSearchReducer from "@/reducers/catalog-search-reducer";
import catalogItemReducer from "@/reducers/catalog-item-reducer";
import catalogCrudReducer from "@/reducers/catalog-crud.reducer";
import mediaReducer from "@/reducers/media-reducer";
import mediaUploadReducer from "@/reducers/media-upload-reducer";
import authorsSearchReducer from "@/reducers/authors-search.reducer";
import authorsCrudReducer from "@/reducers/authors-crud.reducer";
import objectSearchReducer from "@/reducers/object-search.reducer";
import objectCrudReducer from "@/reducers/object-crud.reducer";
import locationSearchReducer from "@/reducers/location-search.reducer";
import locationCrudReducer from "@/reducers/location-crud.reducer";
import materialsSearchReducer from "@/reducers/materials-search.reducer";
import materialsCrudReducer from "@/reducers/materials-crud.reducer";
import technicsSearchReducer from "@/reducers/technics-search.reducer";
import technicsCrudReduce from "@/reducers/technics-crud.reduce";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    modal: modalReducer,
    nav: navReducer,
    config: configReducer,
    catalogSearch: catalogSearchReducer,
    catalogItem: catalogItemReducer,
    catalogCrud: catalogCrudReducer,
    media: mediaReducer,
    mediaUpload: mediaUploadReducer,
    authorsSearch: authorsSearchReducer,
    authorsCrud: authorsCrudReducer,
    objectsSearch: objectSearchReducer,
    objectsCrud: objectCrudReducer,
    locationsSearch: locationSearchReducer,
    locationsCrud: locationCrudReducer,
    materialsSearch: materialsSearchReducer,
    materialsCrud: materialsCrudReducer,
    technicsSearch: technicsSearchReducer,
    technicsCrud: technicsCrudReduce,
  },
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;

export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
