import { ICatalogItem } from "@/models/catalog.model";
import { IAuthor, ILocation, IMaterial, IObject, ITechnic } from "@/models/types";

export const getAppDataParamById = (data, id) => {
  if (!data || !id) {
    return "";
  }
  const label = data.filter((item) => item.ID === parseInt(id, 10));

  return label?.length > 0 ? label[0].post_title : "";
};

export const defaultAuthor: IAuthor = {
  post_title: "",
  birthPlace: "",
  birthDate: "",
  deathDate: "",
};

export const defaultObject: IObject = {
  post_title: "",
};

export const defaultLocation: ILocation = {
  post_title: "",
  city: "",
};

export const defaultMaterial: IMaterial = {
  post_title: "",
};

export const defaultTechnic: ITechnic = {
  post_title: "",
};

export const defaultCatalogItem: ICatalogItem = {
  post_title: "",
  author: "",
  object: null as unknown as number,
  location: null as unknown as number,
  date: null as unknown as number,
  measures: "",
  material: null as unknown as number,
  technic: null as unknown as number,
  content: "",
  featured_media: null as unknown as number,
  notes: "[]",
  bibliography: "[]",
  gallery: "[]",
  documents: "[]",
};

type OptionType = {
  label: string;
  value: string | number | boolean;
};

export const createOptions = (data) => {
  const options: OptionType[] = data?.map((item) => {
    return { value: item.ID, label: item.post_title };
  });
  return options;
};
