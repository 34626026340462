import { Icon } from "@/components/icon";
import { Link } from "react-router-dom";
import { useAppSelector } from "src/config/store";
import { ICatalogItem } from "src/models/catalog.model";
import { getAppDataParamById } from "src/utils/utils";
import { Text } from "@/components/text";

type Props = {
  item: ICatalogItem;
};

const CatalogCard = (props: Props) => {
  const { ID, post_title, featured_media, object, meta } = props.item;
  const { data } = useAppSelector((state) => state.config);

  const style = { backgroundImage: `url(${featured_media})` };

  return (
    <>
      <tr className="table-item catalog-card">
        <td>
          <Link to={`catalog/${ID}/view`}>
            <div className="cover" style={style}></div>
          </Link>
        </td>

        <td>
          <Link to={`catalog/${ID}/view`}>{post_title}</Link>
        </td>
        <td>{getAppDataParamById(data?.objects, object)}</td>
        <td>{meta && <Text type="date" format="DATETIME" content={meta.createdAt} />}</td>
        <td>{meta && <Text type="date" format="DATETIME" content={meta.modifiedAt} />}</td>
        <td>{meta?.createdBy}</td>

        <td>
          <Link to={`/catalog/${ID}/view`} className="button white sm">
            <Icon content="arrowRight" />
          </Link>
        </td>
      </tr>
    </>
  );
};

export default CatalogCard;
