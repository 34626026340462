const minLenght = (value: number) => ({
  minLength: {
    value,
    message: "minLength",
  },
});
const maxLenght = (value: number) => ({
  maxLength: {
    value,
    message: "maxLength",
  },
});

const min = (value: number) => ({
  min: {
    value,
    message: "min",
  },
});
const max = (value: number) => ({
  max: {
    value,
    message: "max",
  },
});

const required = { required: "required" };

const pattern = {
  nAn: {
    pattern: {
      value: /^\d+$/,
      message: "pattern.nan",
    },
  },
  taxCode: {
    pattern: {
      value: /^[A-Za-z]{6}[0-9]{2}[A-Za-z]{1}[0-9]{2}[A-Za-z]{1}[0-9]{3}[A-Za-z]{1}$/i,
      message: "pattern.fiscalCode",
    },
  },
  email: {
    pattern: {
      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
      message: "pattern.email",
    },
  },
  password: {
    pattern: {
      value: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[\w~!-@#$&*]{6,20}$/,
      message: "pattern.password",
    },
  },
  blank: {
    pattern: {
      value: /^\S+$/i,
      message: "pattern.blank",
    },
  },
  letters: {
    pattern: {
      value: /^[a-zA-Z]+$/i,
      message: "pattern.letters",
    },
  },
  username: {
    pattern: {
      value: /^[a-zA-Z0-9-_.]+$/,
      message: "pattern.blankOrCharNotAllowed",
    },
  },
  website: {
    pattern: {
      value:
        /^https?:\/\/(?:www\.|(?!www)[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})+$/,
      message: "pattern.website",
    },
  },
  name: {
    pattern: {
      value: /^[^!@#$%^&*()_+\-=\\[\]{};':"\\|,.<>\\/?]+$/,
      message: "pattern.charNotAllowed",
    },
  },
};

export const validationSchema = {
  required,
  userName: {
    ...required,
    ...minLenght(3),
    ...maxLenght(50),
    ...pattern.username,
  },
  date: {
    ...minLenght(4),
    ...maxLenght(4),
    ...pattern.nAn,
  },
  social: {
    ...minLenght(3),
    ...maxLenght(50),
    ...pattern.username,
  },
  website: {
    ...pattern.website,
  },
  password: {
    ...required,
    ...pattern.password,
  },
  confirmPassword: {
    ...required,
    match: {
      message: "passwordNotMatch",
    },
  },
  firstName: {
    ...required,
    ...maxLenght(50),
    ...pattern.name,
  },
  lastName: {
    ...required,
    ...maxLenght(50),
    ...pattern.name,
  },
  companyName: {
    ...required,
    ...minLenght(3),
    ...maxLenght(50),
  },
  taxCode: {
    ...required,
    ...pattern.taxCode,
  },
  mobileLine: {
    ...required,
    ...minLenght(8),
    ...maxLenght(20),
    ...pattern.nAn,
  },
  landLine: {
    ...minLenght(8),
    ...maxLenght(20),
    ...pattern.nAn,
  },
  rui: {
    ...required,
    ...minLenght(9),
    ...maxLenght(9),
  },
  vat: {
    ...required,
    ...minLenght(11),
    ...maxLenght(13),
  },
  email: {
    ...required,
    ...pattern.email,
  },
  street: {
    ...required,
    ...minLenght(3),
    ...maxLenght(50),
  },
  streetNumber: {
    ...required,
    ...maxLenght(5),
    ...pattern.nAn,
  },
  zipCode: {
    ...required,
    ...maxLenght(5),
    ...pattern.nAn,
  },
  town: {
    ...required,
    ...minLenght(3),
    ...maxLenght(50),
  },
  province: {
    ...required,
    ...maxLenght(2),
    ...pattern.letters,
  },
  locationName: {
    ...required,
    ...minLenght(2),
  },
  // TODO define fiscal code validation
  fiscalCode: {
    ...required,
    ...minLenght(10),
  },
  productName: {
    ...required,
    ...minLenght(3),
    ...maxLenght(50),
  },
  productCode: {
    ...required,
  },
  lotCode: {
    ...required,
  },
  lotQuantity: {
    ...required,
    ...pattern.nAn,
    ...min(1),
    ...max(2147483647),
  },
  certificateName: {
    ...required,
    ...minLenght(3),
    ...maxLenght(50),
  },
  certificateDescription: {
    ...minLenght(3),
    ...maxLenght(250),
  },
};
