import styles from "./icon.module.scss";

import { ReactComponent as account } from "./icon-account.svg";
import { ReactComponent as bell } from "./icon-bell.svg";
import { ReactComponent as menuCollapse } from "./icon-menu-collapse.svg";
import { ReactComponent as menuCollapsed } from "./icon-menu-collapsed.svg";
import { ReactComponent as users } from "./icon-users.svg";
import { ReactComponent as arrowDown } from "./icon-arrow-down.svg";
import { ReactComponent as arrowUp } from "./icon-arrow-up.svg";
import { ReactComponent as arrowLeft } from "./icon-arrow-left.svg";
import { ReactComponent as arrowLeftDouble } from "./icon-arrow-left-double.svg";
import { ReactComponent as arrowRight } from "./icon-arrow-right.svg";
import { ReactComponent as arrowRightDouble } from "./icon-arrow-right-double.svg";
import { ReactComponent as sortList } from "./icon-sort-list.svg";
import { ReactComponent as sortAsc } from "./icon-sort-list-asc.svg";
import { ReactComponent as sortDesc } from "./icon-sort-list-desc.svg";
import { ReactComponent as sort } from "./icon-sort.svg";
import { ReactComponent as filter } from "./icon-filter.svg";
import { ReactComponent as bin } from "./icon-bin.svg";
import { ReactComponent as edit } from "./icon-edit.svg";
import { ReactComponent as eye } from "./icon-eye.svg";
import { ReactComponent as refresh } from "./icon-refresh.svg";
import { ReactComponent as logout } from "./icon-logout.svg";
import { ReactComponent as insuree } from "./icon-insuree.svg";
import { ReactComponent as image } from "./icon-image.svg";
import { ReactComponent as password } from "./icon-password.svg";
import { ReactComponent as search } from "./icon-search.svg";
import { ReactComponent as star } from "./icon-star.svg";
import { ReactComponent as starSolid } from "./icon-star-solid.svg";
import { ReactComponent as dotsHor } from "./icon-dots-horizontal.svg";
import { ReactComponent as quotations } from "./icon-quotations.svg";
import { ReactComponent as home } from "./icon-home.svg";
import { ReactComponent as transactions } from "./icon-transactions.svg";
import { ReactComponent as costs } from "./icon-costs.svg";
import { ReactComponent as arrowLeftThin } from "./icon-arrow-left-thin.svg";
import { ReactComponent as close } from "./icon-close.svg";
import { ReactComponent as payments } from "./icon-payments.svg";
import { ReactComponent as invitation } from "./icon-invitation.svg";
import { ReactComponent as checked } from "./icon-checked.svg";
import { ReactComponent as products } from "./icon-products.svg";
import { ReactComponent as categories } from "./icon-categories.svg";
import { ReactComponent as zones } from "./icon-zones.svg";
import { ReactComponent as chat } from "./icon-chat.svg";
import { ReactComponent as wallet } from "./icon-wallet.svg";
import { ReactComponent as download } from "./icon-download.svg";
import { ReactComponent as upload } from "./icon-upload.svg";
import { ReactComponent as add } from "./icon-add.svg";
import { ReactComponent as areas } from "./icon-areas.svg";
import { ReactComponent as twitter } from "./icon-twitter.svg";
import { ReactComponent as instagram } from "./icon-instagram.svg";
import { ReactComponent as instagramColor } from "./icon-instagram-color.svg";
import { ReactComponent as telegram } from "./icon-telegram.svg";
import { ReactComponent as facebook } from "./icon-facebook.svg";
import { ReactComponent as youtube } from "./icon-youtube.svg";
import { ReactComponent as linkedin } from "./icon-linkedin.svg";
import { ReactComponent as clubhouse } from "./icon-clubhouse.svg";
import { ReactComponent as reddit } from "./icon-reddit.svg";
import { ReactComponent as login } from "./icon-login.svg";
import { ReactComponent as security } from "./icon-security.svg";
import { ReactComponent as email } from "./icon-email.svg";
import { ReactComponent as lock } from "./icon-lock.svg";
import { ReactComponent as view } from "./icon-view.svg";
import { ReactComponent as viewOff } from "./icon-view-off.svg";
import { ReactComponent as link } from "./icon-link.svg";
import { ReactComponent as matic } from "./icon-matic.svg";
import { ReactComponent as metadata } from "./icon-metadata.svg";
import { ReactComponent as externalUrl } from "./icon-external-url.svg";
import { ReactComponent as notifications } from "./icon-notifications.svg";
import { ReactComponent as billing } from "./icon-billing.svg";
import { ReactComponent as arrowLeftPaginate } from "./icon-arrow-left-paginate.svg";
import { ReactComponent as arrowRightPaginate } from "./icon-arrow-right-paginate.svg";
import { ReactComponent as google } from "./icon-google.svg";
import { ReactComponent as apple } from "./icon-apple.svg";
import { ReactComponent as favouriteStroke } from "./icon-favourite-stroke.svg";
import { ReactComponent as favouriteFill } from "./icon-favourite-fill.svg";
import { ReactComponent as countdown } from "./icon-countdown.svg";
import { ReactComponent as copy } from "./icon-copy.svg";
import { ReactComponent as magnify } from "./icon-magnify.svg";
import { ReactComponent as minify } from "./icon-minify.svg";
import { ReactComponent as eur } from "./icon-eur.svg";
import { ReactComponent as myArtworks } from "./icon-my-artworks.svg";
import { ReactComponent as myAuctions } from "./icon-my-auction.svg";
import { ReactComponent as offerRequests } from "./icon-offer-requests.svg";
import { ReactComponent as dashboardTransactions } from "./icon-dashboard-transactions.svg";
import { ReactComponent as dashboardNotifications } from "./icon-dashboard-notifications.svg";
import { ReactComponent as editPencil } from "./icon-edit-pencil.svg";
import { ReactComponent as camera } from "./icon-camera.svg";
import { ReactComponent as twitch } from "./icon-twitch.svg";
import { ReactComponent as website } from "./icon-website.svg";
import { ReactComponent as at } from "./icon-at.svg";
import { ReactComponent as menuUser } from "./icon-menu-user.svg";
import { ReactComponent as withDraw } from "./icon-withdraw.svg";
import { ReactComponent as rocket } from "./icon-rocket.svg";
import { ReactComponent as modalClose } from "./icon-modal-close.svg";
import { ReactComponent as dotsHorMore } from "./icon-dots-hor.svg";
import { ReactComponent as detailsIpsf } from "./icon-ipsf.svg";
import { ReactComponent as detailsXrpl } from "./icon-search-xrpl.svg";
import { ReactComponent as share } from "./icon-share.svg";
import { ReactComponent as shareTwitter } from "./icon-share-twitter.svg";
import { ReactComponent as shareWhatsapp } from "./icon-share-whatsapp.svg";
import { ReactComponent as shareCopy } from "./icon-share-copy.svg";
import { ReactComponent as filterClose } from "./icon-close-filter.svg";
import { ReactComponent as collections } from "./icon-collections.svg";
import { ReactComponent as littleStar } from "./icon-little-star.svg";
import { ReactComponent as video } from "./icon-video.svg";
import { ReactComponent as file } from "./icon-file.svg";
import { ReactComponent as blockchain } from "./icon-blockchain.svg";
import { ReactComponent as qrCode } from "./icon-qr-code.svg";
import { ReactComponent as print } from "./icon-print.svg";
import { ReactComponent as dashboard } from "./icon-dashboard.svg";
import { ReactComponent as company } from "./icon-company.svg";
import { ReactComponent as list } from "./icon-list.svg";
import { ReactComponent as hamburger } from "./icon-hamburger.svg";
import { ReactComponent as collapse } from "./icon-collapse.svg";
import { ReactComponent as expand } from "./icon-expand.svg";
import { ReactComponent as lot } from "./icon-lot.svg";
import { ReactComponent as product } from "./icon-product.svg";
import { ReactComponent as settings } from "./icon-settings.svg";
import { ReactComponent as blockSizeSm } from "./icon-block-size-sm.svg";
import { ReactComponent as blockSizeMd } from "./icon-block-size-md.svg";
import { ReactComponent as blockSizeLg } from "./icon-block-size-lg.svg";
import { ReactComponent as blockPositionStart } from "./icon-block-position-start.svg";
import { ReactComponent as blockPositionCenter } from "./icon-block-position-center.svg";
import { ReactComponent as blockPositionEnd } from "./icon-block-position-end.svg";
import { ReactComponent as blockMarginSm } from "./icon-block-margin-sm.svg";
import { ReactComponent as blockMarginMd } from "./icon-block-margin-md.svg";
import { ReactComponent as blockMarginLg } from "./icon-block-margin-lg.svg";
import { ReactComponent as blockTypeText } from "./icon-block-text.svg";
import { ReactComponent as blockTypeImage } from "./icon-block-image.svg";
import { ReactComponent as blockTypeVideo } from "./icon-block-video.svg";
import { ReactComponent as blockTypeSlider } from "./icon-block-slider.svg";
import { ReactComponent as blockTypeEmbed } from "./icon-block-embed.svg";
import { ReactComponent as blockOrder } from "./icon-block-order.svg";
import { ReactComponent as trash } from "./icon-trash.svg";
import { ReactComponent as centerMap } from "./icon-center-map.svg";
import { ReactComponent as remove } from "./icon-remove.svg";
import { ReactComponent as social } from "./icon-social.svg";
import { ReactComponent as toastSuccess } from "./icon-toast-success.svg";
import { ReactComponent as toastError } from "./icon-toast-error.svg";
import { ReactComponent as treemap } from "./icon-tree-map.svg";
import { ReactComponent as companyRequest } from "./icon-company-request.svg";
import { ReactComponent as fullscreen } from "./icon-fullscreen.svg";
import { ReactComponent as authors } from "./icon-authors.svg";
import { ReactComponent as location } from "./icon-location.svg";
import { ReactComponent as media } from "./icon-media.svg";
import { ReactComponent as material } from "./icon-material.svg";
import { ReactComponent as technic } from "./icon-technic.svg";
import { ReactComponent as objects } from "./icon-object.svg";
import { ReactComponent as info } from "./icon-info.svg";
import { ReactComponent as catalog } from "./icon-catalog.svg";

import { TIcon } from "../../models/icon.model";
import cx from "classnames";

const IconData: IconProps = {
  account,
  menuCollapse,
  menuCollapsed,
  arrowDown,
  arrowUp,
  sortList,
  sort,
  sortAsc,
  sortDesc,
  filter,
  arrowLeft,
  arrowLeftDouble,
  arrowRight,
  arrowRightDouble,
  bell,
  bin,
  edit,
  eye,
  refresh,
  logout,
  insuree,
  image,
  password,
  search,
  star,
  starSolid,
  dotsHor,
  home,
  transactions,
  costs,
  arrowLeftThin,
  quotations,
  close,
  payments,
  invitation,
  checked,
  products,
  categories,
  zones,
  chat,
  wallet,
  download,
  upload,
  add,
  areas,
  twitter,
  instagram,
  instagramColor,
  telegram,
  facebook,
  youtube,
  linkedin,
  clubhouse,
  reddit,
  login,
  security,
  email,
  lock,
  view,
  viewOff,
  link,
  matic,
  metadata,
  externalUrl,
  notifications,
  billing,
  arrowLeftPaginate,
  arrowRightPaginate,
  google,
  apple,
  favouriteStroke,
  favouriteFill,
  countdown,
  copy,
  magnify,
  minify,
  eur,
  myArtworks,
  myAuctions,
  offerRequests,
  dashboardTransactions,
  dashboardNotifications,
  info,
  editPencil,
  camera,
  twitch,
  website,
  at,
  menuUser,
  withDraw,
  rocket,
  modalClose,
  dotsHorMore,
  detailsIpsf,
  detailsXrpl,
  share,
  shareTwitter,
  shareWhatsapp,
  shareCopy,
  filterClose,
  collections,
  littleStar,
  location,
  video,
  file,
  blockchain,
  qrCode,
  print,
  users,
  dashboard,
  company,
  list,
  hamburger,
  collapse,
  expand,
  lot,
  product,
  settings,
  blockSizeSm,
  blockSizeMd,
  blockSizeLg,
  blockPositionStart,
  blockPositionCenter,
  blockPositionEnd,
  blockMarginSm,
  blockMarginMd,
  blockMarginLg,
  blockTypeText,
  blockTypeImage,
  blockTypeVideo,
  blockTypeSlider,
  blockTypeEmbed,
  blockOrder,
  trash,
  centerMap,
  remove,
  social,
  toastSuccess,
  toastError,
  treemap,
  companyRequest,
  fullscreen,
  catalog,
  authors,
  objects,
  material,
  technic,
  media,
};

type IconProps = {
  [K in TIcon]: any;
};

type Props = {
  content: TIcon;
  className?: string;
  size?: "xxxs" | "xxs" | "xs" | "sm" | "smd" | "md" | "mdl" | "lg";
  color?: "grey" | "white" | "black";
  ariaLabel?: string;
};

export const Icon = (props: Props) => {
  const { content, className, size = "sm", color = "black", ariaLabel } = props;

  const compressedClasses = cx(styles.icon, styles[size], styles[color], className);
  const Svg = IconData[content];

  return (
    <div aria-label={ariaLabel} className={compressedClasses}>
      <Svg />
    </div>
  );
};
