import { useState, useEffect } from "react";
import axios from "axios";
import { SERVER_API_URL } from "src/config/constants";

type TGalleryCardProps = {
  imageId: number;
};
export const MediaCard = (props: TGalleryCardProps) => {
  const { imageId } = props;
  const [imageUrl, setImageUrl] = useState<string | null>(null);

  const getImageByID = async (id: number) => {
    try {
      const result = await axios.get(`${SERVER_API_URL}/wp/v2/media/${id}`);
      setImageUrl(result.data.source_url);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getImageByID(imageId);
  }, [imageId]);

  return (
    <div className="GalleryCard">
      <div className="image" style={{ backgroundImage: `url(${imageUrl})` }}></div>
    </div>
  );
};
