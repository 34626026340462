import { Button } from "@/components/button";
import { Icon } from "@/components/icon";
import { Text } from "@/components/text";
import { IAuthor } from "@/models/types";
import { openModal } from "@/reducers/modal-reducer";
import { useAppDispatch } from "src/config/store";
import AuthorsEdit from "./authors-edit";
import { DeleteModal } from "@/components/delete-modal/delete-modal";
import { deleteAuthor } from "@/reducers/authors-crud.reducer";

type Props = {
  author: IAuthor;
  className?: string;
};

const AuthorsCard = (props: Props) => {
  const { author, className } = props;

  const dispatch = useAppDispatch();

  const handleEdit = () => {
    dispatch(
      openModal({
        element: <AuthorsEdit author={author} />,
        opt: { size: "xs", title: "Modifica autore" },
      })
    );
  };

  const handleDelete = () => {
    dispatch(
      openModal({
        element: (
          <DeleteModal
            message={`Confermi di voler eliminare l'autore ${author.post_title}?`}
            onConfirm={onDeleteConfirm}
            stateKey={"authorsCrud"}
          />
        ),
        opt: { size: "xs", title: "Elimina autore" },
      })
    );
  };

  const onDeleteConfirm = () => {
    dispatch(deleteAuthor(author.ID));
  };

  return (
    <tr className={`table-item ${className || ""} `}>
      <td>{author.post_title}</td>
      <td>{author.createdAt ? <Text type="date" format="DATETIME" content={author.createdAt} /> : "-"}</td>
      <td>
        <div className="button-group justify-end">
          <Button size="sm" color="white" onClick={handleEdit}>
            <Icon content="edit" />
          </Button>
          <Button size="sm" color="white" onClick={handleDelete}>
            <Icon content="bin" />
          </Button>
        </div>
      </td>
    </tr>
  );
};

export default AuthorsCard;
