import Modal from "./components/modal/modal";
import { useAppDispatch, useAppSelector } from "./config/store";
import { getSession } from "./reducers/auth-reducer";
import { getConfig } from "./reducers/config-reducer";
import AppRoutes from "./routes";
import { useEffect } from "react";

export const AppWrapper = () => {
  const dispatch = useAppDispatch();
  const { isAuthenticated } = useAppSelector((state) => state.auth);

  useEffect(() => {
    dispatch(getSession());
    isAuthenticated && dispatch(getConfig());
  }, [isAuthenticated]);

  return (
    <>
      <AppRoutes />
      <Modal />
    </>
  );
};
