import { useState, useEffect } from "react";
import axios from "axios";
import { SERVER_API_URL } from "src/config/constants";
import { Button } from "@/components/button";
import { Icon } from "@/components/icon";

type TGalleryCardProps = {
  imageId: number;
  onRemoveImage: (id: number) => void;
};

export const MediaCard = (props: TGalleryCardProps) => {
  const { imageId, onRemoveImage } = props;
  const [imageUrl, setImageUrl] = useState<string>("");

  const getImageByID = async (id: number) => {
    try {
      const result = await axios.get(`${SERVER_API_URL}/wp/v2/media/${id}`);
      setImageUrl(result.data.source_url);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getImageByID(imageId);
  }, [imageId]);

  return (
    <div className="GalleryCard">
      <Button size="sm" color="white" onClick={() => onRemoveImage(imageId)}>
        <Icon content="bin" />
      </Button>
      <div className="image" style={{ backgroundImage: `url(${imageUrl})` }}></div>
    </div>
  );
};
