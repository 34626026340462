import { Button } from "@/components/button";
import { DeleteModal } from "@/components/delete-modal/delete-modal";
import { Icon } from "@/components/icon";
import { Text } from "@/components/text";
import { ILocation } from "@/models/types";
import { deleteLocation } from "@/reducers/location-crud.reducer";
import { openModal } from "@/reducers/modal-reducer";
import { useAppDispatch } from "src/config/store";
import LocationsEdit from "./locations-edit";

type Props = {
  location: ILocation;
  className?: string;
};

export const LocationsCard = (props: Props) => {
  const { location, className } = props;

  const dispatch = useAppDispatch();

  const handleEdit = () => {
    dispatch(
      openModal({
        element: <LocationsEdit location={location} />,
        opt: { size: "xs", title: "Modifica collocazione" },
      })
    );
  };

  const handleDelete = () => {
    dispatch(
      openModal({
        element: (
          <DeleteModal
            message={`Confermi di voler eliminare la collocazione ${location.post_title}?`}
            onConfirm={onDeleteConfirm}
            stateKey={"locationsCrud"}
          />
        ),
        opt: { size: "xs", title: "Elimina collocazione" },
      })
    );
  };

  const onDeleteConfirm = () => {
    dispatch(deleteLocation(location.ID));
  };

  return (
    <tr className={`table-item ${className || ""} `}>
      <td>{location.post_title}</td>
      <td>{location.city}</td>
      <td>{location.createdAt && <Text type="date" format="DATETIME" content={location.createdAt} />}</td>
      <td>
        <div className="button-group justify-end">
          <Button size="sm" color="white" onClick={handleEdit}>
            <Icon content="edit" />
          </Button>
          <Button size="sm" color="white" onClick={handleDelete}>
            <Icon content="bin" />
          </Button>
        </div>
      </td>
    </tr>
  );
};
