import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios, { AxiosResponse } from "axios";
import { SERVER_API_URL } from "src/config/constants";
import { ICatalogItem } from "@/models/catalog.model";
import { NavigateFunction } from "react-router-dom";
import { closeModal } from "./modal-reducer";

const INITIAL_STATE = {
  loading: false,
  errorMessage: null as unknown as string,
  fetchSuccess: false,
  fetchFailure: false,
};

export type CatalogCrudState = Readonly<typeof INITIAL_STATE>;

type CreateData = {
  params: ICatalogItem;
  navigate: NavigateFunction;
};

type DeleteData = {
  id: number;
  navigate: NavigateFunction;
};

export const createCatalogItem = createAsyncThunk(
  "catalog/create",
  async (data: CreateData, { rejectWithValue }) => {
    try {
      const { params, navigate } = data;
      const response: AxiosResponse<{ postId: number }> = await axios.post(
        `${SERVER_API_URL}/api/catalog`,
        params
      );

      navigate(`/catalog/${response.data.postId}/edit`);

      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const editCatalogItem = createAsyncThunk(
  "catalog/edit",
  async (params: ICatalogItem, { rejectWithValue }) => {
    try {
      const response: AxiosResponse<boolean> = await axios.put(`${SERVER_API_URL}/api/catalog`, params);

      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const deleteCatalogItem = createAsyncThunk(
  "catalog/delete",
  async (data: DeleteData, { rejectWithValue, dispatch }) => {
    const { id, navigate } = data;

    try {
      const response: AxiosResponse<boolean> = await axios.delete(`${SERVER_API_URL}/api/catalog/${id}`);
      navigate("/catalog");
      dispatch(closeModal());
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

const catalogCrudSlice = createSlice({
  name: "post",
  initialState: INITIAL_STATE,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createCatalogItem.pending, (state) => {
        return {
          ...state,
          loading: true,
        };
      })
      .addCase(createCatalogItem.fulfilled, (state) => {
        return {
          ...state,
          loading: false,
          fetchSuccess: true,
        };
      })
      .addCase(createCatalogItem.rejected, (state) => {
        return {
          ...state,
          loading: false,
          fetchFailure: true,
        };
      })
      .addCase(editCatalogItem.pending, (state) => {
        return {
          ...state,
          loading: true,
        };
      })
      .addCase(editCatalogItem.fulfilled, (state) => {
        return {
          ...state,
          loading: false,
          fetchSuccess: true,
        };
      })
      .addCase(editCatalogItem.rejected, (state) => {
        return {
          ...state,
          loading: false,
          fetchFailure: true,
        };
      })
      .addCase(deleteCatalogItem.pending, (state) => {
        return {
          ...state,
          loading: true,
        };
      })
      .addCase(deleteCatalogItem.fulfilled, (state) => {
        return {
          ...state,
          loading: false,
          fetchSuccess: true,
        };
      })
      .addCase(deleteCatalogItem.rejected, (state) => {
        return {
          ...state,
          loading: false,
          fetchFailure: true,
        };
      });
  },
});

export default catalogCrudSlice.reducer;
