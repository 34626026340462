import { Button } from "@/components/button";
import { DeleteModal } from "@/components/delete-modal/delete-modal";
import { Icon } from "@/components/icon";
import { Text } from "@/components/text";
import { ITechnic } from "@/models/types";
import { openModal } from "@/reducers/modal-reducer";
import { deleteTechnic } from "@/reducers/technics-crud.reduce";
import { useAppDispatch } from "src/config/store";
import { TechnicsEdit } from "./technics-edit";

type Props = {
  technic: ITechnic;
  className?: string;
};

const TechnicsCard = (props: Props) => {
  const { technic, className } = props;

  const dispatch = useAppDispatch();

  const handleEdit = () => {
    dispatch(
      openModal({
        element: <TechnicsEdit technic={technic} />,
        opt: { size: "xs", title: "Modifica tecnica" },
      })
    );
  };

  const handleDelete = () => {
    dispatch(
      openModal({
        element: (
          <DeleteModal
            message={`Confermi di voler eliminare la tecnica ${technic.post_title}?`}
            onConfirm={onDeleteConfirm}
            stateKey={"technicsCrud"}
          />
        ),
        opt: { size: "xs", title: "Elimina tecnica" },
      })
    );
  };

  const onDeleteConfirm = () => {
    dispatch(deleteTechnic(technic.ID));
  };

  return (
    <tr className={`table-item ${className || ""} `}>
      <td>{technic.post_title}</td>
      <td>{technic.createdAt && <Text type="date" format="DATETIME" content={technic.createdAt} />}</td>
      <td>
        <div className="button-group justify-end">
          <Button size="sm" color="white" onClick={handleEdit}>
            <Icon content="edit" />
          </Button>
          <Button size="sm" color="white" onClick={handleDelete}>
            <Icon content="bin" />
          </Button>
        </div>
      </td>
    </tr>
  );
};

export default TechnicsCard;
