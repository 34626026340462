import { toggleNav } from "@/reducers/nav-reducer";
import cx from "classnames";
import { useAppDispatch, useAppSelector } from "src/config/store";

export const Burger = () => {
  const { open } = useAppSelector((state) => state.nav);
  const dispatch = useAppDispatch();

  const className = cx("burger-menu", { ["isOpen"]: open });

  const handleClick = () => {
    dispatch(toggleNav());
  };

  return (
    <div className={className} onClick={handleClick}>
      <div className="dish">
        <div className="burger-item bread-top"></div>
        <div className="burger-item meat"></div>
        <div className="burger-item bread-bottom"></div>
      </div>
    </div>
  );
};
