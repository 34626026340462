import { useEffect, useState } from "react";
import axios from "axios";

import { ICatalogItem } from "src/models/catalog.model";
import { SERVER_API_URL } from "src/config/constants";
import { Icon } from "../icon";

type TFeaturedImageProps = {
  className?: string;
  catalogItem: ICatalogItem;
  onUpdateMedia?: (mediaId?: number) => void;
  canEdit?: boolean;
};

export const FeaturedImage = (props: TFeaturedImageProps) => {
  const { className, catalogItem, onUpdateMedia, canEdit = true } = props;

  const [imageUrl, setImageUrl] = useState<string | null>(null);

  const getImageByID = async (id: number) => {
    try {
      const result = await axios.get(`${SERVER_API_URL}/wp/v2/media/${id}`);
      setImageUrl(result.data.source_url);
      closeMediaManager();
    } catch (error) {
      console.log(error);
    }
  };

  const onSelectImage = (ids: number[]) => {
    getImageByID(ids[0]);
    onUpdateMedia && onUpdateMedia(ids[0]);
  };

  const onRemoveMedia = () => {
    setImageUrl(null);
    onUpdateMedia && onUpdateMedia();
  };

  const openMediaManager = () => {
    // ...
  };
  const closeMediaManager = () => {
    // ...
  };

  useEffect(() => {
    catalogItem?.featured_media && getImageByID(catalogItem?.featured_media);
  }, [catalogItem?.featured_media]);

  return (
    <>
      <div className="featured-container">
        {imageUrl && canEdit && (
          <div className="button sm white remove-featured-image" onClick={() => onRemoveMedia()}>
            <Icon content="bin" />
          </div>
        )}

        <div className="featured-image" style={{ backgroundImage: `url(${imageUrl})` }} />

        {canEdit && (
          <button className="button md primary full-width" onClick={openMediaManager}>
            {imageUrl ? "Cambia immagine" : "Seleziona immagine"}
          </button>
        )}
      </div>

      {/* {isMediaManagerOpen && canEdit && (
        <Modal size="lg" close={closeMediaManager} title="Media manager">
          <MediaManager onSelectImage={onSelectImage} selectMode="SINGLE" />
        </Modal>
      )} */}
    </>
  );
};
