import { useState, useEffect } from "react";
import { v4 as uuidv4 } from "uuid";
import { NoteCard } from "./note-card";
import { INote } from "@/models/catalog.model";
import { FormGroup } from "@/components/form";
import { Flex } from "@/components/flex";
import { Heading } from "@/components/heading";
import { Button } from "@/components/button";
import { Icon } from "@/components/icon";

type Props = {
  notes: INote[];
  onUpdateNotes: (notes: INote[]) => void;
};

export const Notes = (props: Props) => {
  const [notes, setNotes] = useState<INote[]>([]);

  const onNoteAdd = () => {
    const note = {
      content: "",
      id: uuidv4(),
    };

    setNotes([note, ...notes]);
  };

  const onNoteSave = (data, index) => {
    const arr = notes.filter((note) => note.id !== data.id);
    arr.splice(index, 0, data);
    setNotes(arr);
    props.onUpdateNotes(arr);
  };

  const onNoteDelete = (id) => {
    const arr = notes.filter((note) => note.id !== id);
    setNotes(arr);
    props.onUpdateNotes(arr);
  };

  useEffect(() => {
    setNotes(props.notes);
  }, [props.notes]);

  return (
    <FormGroup>
      <Flex container alignItems="center">
        <Heading content={"Note"} size={12} />
        <Button size="sm" color="white" className="ml-auto" onClick={onNoteAdd}>
          <Icon content="add" />
        </Button>
      </Flex>

      {(!notes || notes?.length === 0) && "Nessuna nota inserita"}

      {notes?.map((note, index) => (
        <NoteCard
          key={note.id}
          note={note}
          index={index}
          onNoteSave={onNoteSave}
          onNoteDelete={onNoteDelete}
        />
      ))}
    </FormGroup>
  );
};
