import { ILocation } from "@/models/types";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios, { AxiosResponse } from "axios";
import { SERVER_API_URL } from "src/config/constants";
import { closeModal } from "./modal-reducer";
import { refreshLocationsSearch } from "./location-search.reducer";
import { getConfig } from "./config-reducer";

const INITIAL_STATE = {
  loading: false,
  errorMessage: null as unknown as string,
  fetchSuccess: false,
  fetchFailure: false,
};

export type LocationCrudState = Readonly<typeof INITIAL_STATE>;

export const createLocation = createAsyncThunk(
  "locations/create",
  async (params: ILocation, { rejectWithValue, dispatch }) => {
    try {
      const response: AxiosResponse<boolean> = await axios.post(`${SERVER_API_URL}/api/locations`, params);
      dispatch(refreshLocationsSearch());
      dispatch(closeModal());
      dispatch(getConfig());
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const editLocation = createAsyncThunk(
  "locations/edit",
  async (params: ILocation, { rejectWithValue, dispatch }) => {
    try {
      const response: AxiosResponse<boolean> = await axios.put(`${SERVER_API_URL}/api/locations`, params);
      dispatch(refreshLocationsSearch());
      dispatch(closeModal());
      dispatch(getConfig());
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const deleteLocation = createAsyncThunk(
  "locations/delete",
  async (id: number | undefined, { rejectWithValue, dispatch }) => {
    try {
      const response: AxiosResponse<boolean> = await axios.delete(`${SERVER_API_URL}/api/locations/${id}`);
      dispatch(refreshLocationsSearch());
      dispatch(closeModal());
      dispatch(getConfig());
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

const locationsCrudSlice = createSlice({
  name: "post",
  initialState: INITIAL_STATE,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createLocation.pending, (state) => {
        return {
          ...state,
          loading: true,
        };
      })
      .addCase(createLocation.fulfilled, (state) => {
        return {
          ...state,
          loading: false,
          fetchSuccess: true,
        };
      })
      .addCase(createLocation.rejected, (state) => {
        return {
          ...state,
          loading: false,
        };
      })
      .addCase(editLocation.pending, (state) => {
        return {
          ...state,
          loading: true,
        };
      })
      .addCase(editLocation.fulfilled, (state) => {
        return {
          ...state,
          loading: false,
          fetchSuccess: true,
        };
      })
      .addCase(editLocation.rejected, (state) => {
        return {
          ...state,
          loading: false,
        };
      })
      .addCase(deleteLocation.pending, (state) => {
        return {
          ...state,
          loading: true,
        };
      })
      .addCase(deleteLocation.fulfilled, (state) => {
        return {
          ...state,
          loading: false,
          fetchSuccess: true,
        };
      })
      .addCase(deleteLocation.rejected, (state) => {
        return {
          ...state,
          loading: false,
        };
      });
  },
});

export default locationsCrudSlice.reducer;
