import { Avatar } from "@/components/avatar";
import { Icon } from "@/components/icon";
import { logout } from "@/reducers/auth-reducer";
import { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "src/config/store";
import { IAccount } from "src/models/user.model";

export const MenuUser = () => {
  const { account } = useAppSelector((state) => state.auth);

  const userDropdown = useRef<HTMLDivElement>(null);
  const [isUserMenuOpen, setUserMenuOpen] = useState<boolean>(false);

  function handleClickOutside(event) {
    if (userDropdown.current && !userDropdown.current.contains(event.target)) {
      setUserMenuOpen(false);
    }
  }

  useEffect(() => {
    window.addEventListener("mousedown", handleClickOutside);
    return () => {
      window.removeEventListener("mousedown", handleClickOutside);
    };
  }, [handleClickOutside]);

  return (
    <div className="Menu-user" ref={userDropdown}>
      <div onClick={() => setUserMenuOpen(!isUserMenuOpen)}>
        <UserPreview {...account} />
      </div>
      {isUserMenuOpen && <UserDropdownMenu account={account} onClick={() => setUserMenuOpen(false)} />}
    </div>
  );
};

const UserPreview = (props: IAccount) => {
  const { avatar } = props;
  return (
    <div className="Menu-user-avatar">
      <Avatar avatar={avatar} size="sm" />
    </div>
  );
};

type TUserDropdownMenu = {
  account: IAccount;
  onClick: () => void;
};

const UserDropdownMenu = (props: TUserDropdownMenu) => {
  const { email, firstName, lastName, avatar } = props.account;

  const dispatch = useAppDispatch();

  const doLogout = () => {
    dispatch(logout());
  };

  return (
    <div className="Menu-user-dropdown">
      <div className="user-meta">
        <Avatar avatar={avatar} size="md" />
        <div className="ml-1">
          <div className="user-name">{firstName + " " + lastName}</div>

          <div className="user-email"> {email} </div>
        </div>
      </div>

      <div className="dropdown-menu">
        <div className="user-sep"></div>

        <Link to="/account" className="dropdown-menu-item menu-item" onClick={() => props.onClick()}>
          <Icon content="settings" />
          Settings
        </Link>

        <div className="dropdown-menu-item menu-item" onClick={doLogout}>
          <Icon content="logout" />
          Sign out
        </div>
      </div>
    </div>
  );
};
