import { ILocation, IdefaultSearchParams } from "@/models/types";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios, { AxiosResponse } from "axios";
import { SERVER_API_URL } from "src/config/constants";

const INITIAL_STATE = {
  loading: false,
  errorMessage: null as unknown as string,
  fetchSuccess: false,
  fetchFailure: false,
  search: null as unknown as ILocation[],
  count: null as unknown as number,
  refresh: 0,
};

export type LocationSearchState = Readonly<typeof INITIAL_STATE>;

export const getLocations = createAsyncThunk(
  "locations/search",
  async (params: IdefaultSearchParams, { rejectWithValue }) => {
    try {
      const response: AxiosResponse<ILocation[]> = await axios.post(
        `${SERVER_API_URL}/api/locations/search`,
        params
      );
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

const locationsSearchSlice = createSlice({
  name: "post",
  initialState: INITIAL_STATE,
  reducers: {
    refreshLocationsSearch(state) {
      return {
        ...state,
        refresh: state.refresh + 1,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getLocations.pending, (state) => {
        return {
          ...state,
          loading: true,
        };
      })
      .addCase(getLocations.fulfilled, (state, { payload }) => {
        return {
          ...state,
          loading: false,
          fetchSuccess: true,
          search: payload,
        };
      })
      .addCase(getLocations.rejected, (state) => {
        return {
          ...state,
          loading: false,
          fetchFailure: true,
        };
      });
  },
});

const { actions } = locationsSearchSlice;
export const { refreshLocationsSearch } = actions;
export default locationsSearchSlice.reducer;
