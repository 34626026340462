import "./form.scss";

type FormProps = {
  children: any;
  submit?: any;
  className?: string;
};

export const Form = (props: FormProps) => {
  const { children, submit, className } = props;

  return (
    <div className={`form ${className || ""}`} onSubmit={submit}>
      {children}
    </div>
  );
};

type FormGroupProps = {
  children: any;
};

export const FormGroup = (props: FormGroupProps) => {
  const { children } = props;

  return <div className="form-group">{children}</div>;
};

type FormGroupTitleProps = {
  title: string;
  children?: any;
};

export const FormGroupTitle = (props: FormGroupTitleProps) => {
  const { title, children } = props;
  return (
    <div className="d-flex align-items-center  mb-3">
      <h2 className="heading h-12">{title}</h2>
      {children}
    </div>
  );
};

type FormItemProps = {
  children: any;
  label?: string;
  name?: string;
  className?: string;
};

export const FormItem = (props: FormItemProps) => {
  const { children, label, name, className } = props;

  return (
    <div className={`form-item ${className || ""}`}>
      {label && <label htmlFor={name}> {label} </label>}
      {children}
    </div>
  );
};

type FormErrorProps = {
  error: any;
};

export const FormError = (props: FormErrorProps) => {
  const { error } = props;

  return <div className="form-error">{error}</div>;
};

type FormRowProps = {
  className?: string;
  children: any;
  col?: string;
};

export const FormRow = (props: FormRowProps) => {
  const { children, col, className } = props;

  return <div className={`form-row ${className || ""} ${col ? `cols-${col}` : ""}`}>{children}</div>;
};

type TReadOnly = {
  children: any;
  className?: string;
};

export const ReadOnly = (props: TReadOnly) => {
  const { children, className } = props;
  return <div className={`readOnly ${className || ""}`}>{children}</div>;
};
