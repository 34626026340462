import { useState, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "src/config/store";
import { PageTopbar } from "@/components/page-topbar";
import { Button } from "@/components/button";
import { useLocation, useNavigate } from "react-router-dom";

import { LocationsCard } from "./locations-card";
import {
  TableList,
  TableListBody,
  TableListHeader,
  TableListPagination,
  TTableListHeaderItem,
} from "@/components/table-list";
import { Loader } from "@/components/loader";
import { IdefaultSearchParams, TOrderBy } from "@/models/types";
import { defaultSearchParams, getQueryParamsFromUrl, setParamsToUrl } from "src/utils/search-utils";
import { getLocations } from "@/reducers/location-search.reducer";
import { PrivateRoute } from "@/layouts/private-route";
import LocationsEdit from "./locations-edit";
import { openModal } from "@/reducers/modal-reducer";
import { defaultLocation } from "src/utils/utils";

export const LocationsSearch = () => {
  const dispatch = useAppDispatch();
  const locationsState = useAppSelector((state) => state.locationsSearch);

  const location = useLocation();
  const navigate = useNavigate();

  const [params, setParams] = useState<IdefaultSearchParams>(
    getQueryParamsFromUrl(defaultSearchParams, location)
  );

  const onNewLocation = () => {
    dispatch(
      openModal({
        element: <LocationsEdit location={defaultLocation} />,
        opt: { size: "xs", title: "Nuova collocazione" },
      })
    );
  };

  const onChangePage = (page: number) =>
    setParams({
      ...params,
      offset: params.size * page,
    });

  const getCurrentPage = Math.floor(params.offset / params.size);

  const onChangeSort = (sort: TOrderBy) => () =>
    setParams({
      ...params,
      orderBy: sort,
      order: params.order === "ASC" ? "DESC" : "ASC",
    });

  const tableHeader: TTableListHeaderItem[] = [
    {
      id: "title",
      name: "Titolo",
      onClick: onChangeSort("title"),
    },
    {
      id: "city",
      name: "Città",
      onClick: onChangeSort("city"),
    },
    {
      id: "createdDate",
      name: "Data creazione",
      onClick: onChangeSort("createdDate"),
    },
    {},
  ];

  useEffect(() => {
    dispatch(getLocations(params));
    setParamsToUrl(params, navigate);
  }, [params.orderBy, params.order, params.offset, params.size, locationsState.refresh]);

  useEffect(() => {
    setParams(getQueryParamsFromUrl(defaultSearchParams, location.search));
  }, [location.search]);

  return (
    <PrivateRoute>
      <>
        <PageTopbar title={"Collocazioni"}>
          <div className="button-group ml-auto">
            <Button className="button md solid primary" onClick={onNewLocation}>
              Nuova collocazione
            </Button>
          </div>
        </PageTopbar>

        {locationsState.loading && <Loader color="#000000" />}
        {locationsState.fetchFailure && "Errore caricamento dati"}
        {locationsState.search?.length === 0 && "Nessun dato"}

        {locationsState.search?.length > 0 && (
          <>
            <TableList className="color-black">
              <TableListHeader items={tableHeader} sortBy={params.orderBy} sortDirection={params.order} />
              <TableListBody>
                {locationsState.search?.map((location) => (
                  <LocationsCard key={location.ID} location={location} />
                ))}
              </TableListBody>
            </TableList>

            <TableListPagination
              activePage={getCurrentPage}
              paginate={onChangePage}
              itemsPerPage={params.size}
              totalItems={locationsState.count}
            />
          </>
        )}
      </>
    </PrivateRoute>
  );
};
