import { FormItem, ReadOnly } from "@/components/form";
import { INote } from "src/models/catalog.model";

type Props = {
  note: INote;
};

export const NoteCard = (props: Props) => {
  const { note } = props;

  return (
    <FormItem>
      <ReadOnly> {note.content}</ReadOnly>
    </FormItem>
  );
};
