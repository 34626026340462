import { Provider } from "react-redux";
import "./app.scss";
import { store } from "./config/store";
import { AppWrapper } from "./_app";
import setupAxiosInterceptors from "./config/axios-interceptor";

function App() {
  setupAxiosInterceptors();

  return (
    <>
      <Provider store={store}>
        <AppWrapper />
      </Provider>
    </>
  );
}

export default App;
