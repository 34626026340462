import { IMaterial } from "@/models/types";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios, { AxiosResponse } from "axios";
import { SERVER_API_URL } from "src/config/constants";
import { closeModal } from "./modal-reducer";
import { refreshMaterialsSearch } from "./materials-search.reducer";
import { getConfig } from "./config-reducer";

const INITIAL_STATE = {
  loading: false,
  errorMessage: null as unknown as string,
  fetchSuccess: false,
  fetchFailure: false,
};

export type MaterialsCrudState = Readonly<typeof INITIAL_STATE>;

export const createMaterial = createAsyncThunk(
  "materials/create",
  async (params: IMaterial, { rejectWithValue, dispatch }) => {
    try {
      const response: AxiosResponse<boolean> = await axios.post(`${SERVER_API_URL}/api/materials`, params);
      dispatch(refreshMaterialsSearch());
      dispatch(closeModal());
      dispatch(getConfig());
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const editMaterial = createAsyncThunk(
  "materials/edit",
  async (params: IMaterial, { rejectWithValue, dispatch }) => {
    try {
      const response: AxiosResponse<boolean> = await axios.put(`${SERVER_API_URL}/api/materials`, params);
      dispatch(refreshMaterialsSearch());
      dispatch(closeModal());
      dispatch(getConfig());
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const deleteMaterial = createAsyncThunk(
  "materials/delete",
  async (id: number | undefined, { rejectWithValue, dispatch }) => {
    try {
      const response: AxiosResponse<boolean> = await axios.delete(`${SERVER_API_URL}/api/materials/${id}`);
      dispatch(refreshMaterialsSearch());
      dispatch(closeModal());
      dispatch(getConfig());
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

const materialsCrudSlice = createSlice({
  name: "post",
  initialState: INITIAL_STATE,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createMaterial.pending, (state) => {
        return {
          ...state,
          loading: true,
        };
      })
      .addCase(createMaterial.fulfilled, (state) => {
        return {
          ...state,
          loading: false,
          fetchSuccess: true,
        };
      })
      .addCase(createMaterial.rejected, (state) => {
        return {
          ...state,
          loading: false,
        };
      })
      .addCase(editMaterial.pending, (state) => {
        return {
          ...state,
          loading: true,
        };
      })
      .addCase(editMaterial.fulfilled, (state) => {
        return {
          ...state,
          loading: false,
          fetchSuccess: true,
        };
      })
      .addCase(editMaterial.rejected, (state) => {
        return {
          ...state,
          loading: false,
        };
      })
      .addCase(deleteMaterial.pending, (state) => {
        return {
          ...state,
          loading: true,
        };
      })
      .addCase(deleteMaterial.fulfilled, (state) => {
        return {
          ...state,
          loading: false,
          fetchSuccess: true,
        };
      })
      .addCase(deleteMaterial.rejected, (state) => {
        return {
          ...state,
          loading: false,
        };
      });
  },
});

export default materialsCrudSlice.reducer;
