import { Flex } from "../flex";
import { Icon } from "../icon";
import "./table-list.scss";

type TTableList = {
  children: any;
  className?: string;
};

export const TableList = (props: TTableList) => {
  const { children, className } = props;
  return (
    <div className={`Table-list ${className || ""}`}>
      <table>{children}</table>
    </div>
  );
};

type TTableListHeader = {
  items: TTableListHeaderItem[];
  sortBy?: string;
  sortDirection?: string;
};

export type TTableListHeaderItem = {
  id?: string;
  name?: string;
  onClick?: (param: string) => void;
};

export const TableListHeader = (props: TTableListHeader) => {
  const { items, sortBy, sortDirection } = props;

  const handleItemSort = (item) => {
    if (item.id && item.onClick) {
      item.onClick(item.id);
    }
  };

  return (
    <thead>
      <tr>
        {items.map((item, i) => {
          const isActive = item.id === sortBy;
          return (
            <th key={i}>
              <div>
                {item.name && (
                  <>
                    <span>{item.name}</span>
                    {item.id && item.onClick && (
                      <div
                        className={`handle-sort ${isActive ? "active" : ""}`}
                        onClick={() => handleItemSort(item)}
                      >
                        {!sortDirection || (sortDirection && !isActive && <Icon content="sortList" />)}
                        {sortDirection &&
                          isActive &&
                          (sortDirection === "ASC" ? (
                            <Icon content="sortAsc" />
                          ) : (
                            <Icon content="sortDesc" />
                          ))}
                      </div>
                    )}
                  </>
                )}
              </div>
            </th>
          );
        })}
      </tr>
    </thead>
  );
};

type TTableListBody = {
  children: any;
};

export const TableListBody = (props: TTableListBody) => {
  const { children } = props;
  return <tbody>{children}</tbody>;
};

type TTableListPagination = {
  activePage: number;
  itemsPerPage: number;
  totalItems: number;
  paginate: any;
};

export const TableListPagination = (props: TTableListPagination) => {
  const { activePage, itemsPerPage, totalItems, paginate } = props;
  const lastPage = Math.ceil(totalItems / itemsPerPage);

  const goToFirstPage = () => {
    paginate(1);
  };
  const goToPrevPage = () => {
    paginate(activePage - 1);
  };
  const goToNextPage = () => {
    paginate(activePage + 1);
  };
  const goToLastPage = () => {
    paginate(lastPage);
  };

  if (!totalItems || totalItems <= itemsPerPage) {
    return null;
  }

  return (
    <Flex container justifyContent="center" alignItems="center" className="Table-list-pagination mt-1">
      <button className="button solid white circle sm" disabled={activePage === 1} onClick={goToFirstPage}>
        <Icon content="arrowLeftDouble"></Icon>
      </button>

      <button
        className="button solid white circle sm mr-4"
        disabled={activePage === 1}
        onClick={goToPrevPage}
      >
        <Icon content="arrowLeft"></Icon>
      </button>

      <div className="pagination-position">
        <b>{activePage}</b> di {lastPage}
      </div>

      <button
        className="button solid white circle sm ml-4"
        disabled={activePage === lastPage}
        onClick={goToNextPage}
      >
        <Icon content="arrowRight"></Icon>
      </button>

      <button
        className="button solid white circle sm"
        disabled={activePage === lastPage}
        onClick={goToLastPage}
      >
        <Icon content="arrowRightDouble"></Icon>
      </button>
    </Flex>
  );
};
