import "./header.scss";

import { Brand } from "./brand";
import { Flex } from "../flex";
import { NavTop } from "./navs/nav-top";
import { Burger, MenuPrimary, MenuUser } from "./menus";
import { NavSide } from "./navs/nav-side";

export const Header = () => {
  return (
    <>
      <NavTop>
        <Burger />
        <Brand className="m-auto" />
        <Flex container alignItems="center">
          <MenuUser />
        </Flex>
      </NavTop>

      <NavSide>
        <Brand />
        <MenuPrimary />
      </NavSide>
    </>
  );
};
