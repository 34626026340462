import { FormGroup, FormGroupTitle, FormRow } from "@/components/form";
import { BibliographyCard } from "./bibliography-card";

type Props = {
  bibliographies: string;
};

export const Bibliographies = (props: Props) => {
  const bibliographies = JSON.parse(props.bibliographies);

  return (
    <FormGroup>
      <FormGroupTitle title={"Bibliografia"} />
      <FormRow>
        {(!bibliographies || bibliographies?.length === 0) && "Nessuna bibliografia inserita"}

        {bibliographies?.map((bibliography) => (
          <BibliographyCard key={bibliography.id} bibliography={bibliography} />
        ))}
      </FormRow>
    </FormGroup>
  );
};
