import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios, { AxiosResponse } from "axios";
import { SERVER_API_URL } from "src/config/constants";
import { IConfig } from "src/models/types";

const INITIAL_STATE = {
  loading: false,
  errorMessage: null as unknown as string,
  fetchSuccess: false,
  fetchFailure: false,
  data: null as unknown as IConfig,
};

export type ConfigState = Readonly<typeof INITIAL_STATE>;

export const getConfig = createAsyncThunk("config/data", async (data, { rejectWithValue }) => {
  try {
    const response: AxiosResponse<IConfig> = await axios.get(`${SERVER_API_URL}/api/session/appData`);
    return response.data;
  } catch (error: any) {
    return rejectWithValue(error.message);
  }
});

const configSlice = createSlice({
  name: "post",
  initialState: INITIAL_STATE,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getConfig.pending, (state) => {
        return {
          ...state,
          loading: true,
        };
      })
      .addCase(getConfig.fulfilled, (state, { payload }) => {
        return {
          ...state,
          loading: false,
          fetchSuccess: true,
          data: payload,
        };
      })
      .addCase(getConfig.rejected, (state) => {
        return {
          ...state,
          loading: false,
          fetchFailure: true,
        };
      });
  },
});

export default configSlice.reducer;
