import { Icon } from "@/components/icon";
import { NavLink, useLocation } from "react-router-dom";
import { IMenuItem } from "src/models/types";
import cx from "classnames";

export const MenuItem = (props: IMenuItem) => {
  const { icon, to, title } = props;
  const location = useLocation();

  const className = cx("menu-item", { active: location.pathname === to });

  return (
    <NavLink to={to} className={className}>
      {icon && <Icon content={icon} />}
      <span>{title}</span>
    </NavLink>
  );
};
