import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { ICatalogParams, createOptions } from "src/utils/catalog-utils";
import { useAppSelector } from "src/config/store";
import { Button } from "@/components/button";
import { Icon } from "@/components/icon";
import { Form, FormError, FormItem, FormRow } from "@/components/form";
import { validationSchema } from "src/utils/validation-utils";
import { Select } from "@/components/select";
import "./catalog-filters.scss";

type Props = {
  showFilters: boolean;
  toggleFilters: () => void;
  params: ICatalogParams;
  onRemove: (key: string) => void;
  onChange: (params: any) => void;
};

export const CatalogFilters = (props: Props) => {
  const { params, showFilters, toggleFilters, onRemove, onChange } = props;

  const { data } = useAppSelector((state) => state.config);
  const [activeParams, setActiveParams] = useState({});

  const onSelectChange = (key, value) => {
    setValue(key, value);
  };

  const applyFilters = (p: ICatalogParams) => {
    onChange(p);
    toggleFilters();
  };

  const onSetActiveParams = () => {
    const filtered = {};
    Object.entries(params)?.forEach(([key, value]) => {
      return value ? (filtered[key] = value) : null;
    });
    setActiveParams(filtered);
  };

  const getParamValue = (key, value) => {
    return `${key}s` in data ? data[`${key}s`].filter((item) => item.ID === value)[0]?.post_title : value;
  };

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    reset,
    formState: { errors },
  } = useForm({ mode: "onChange", defaultValues: params });

  useEffect(() => {
    reset(params);
    onSetActiveParams();
  }, [params]);

  return (
    <div className="Filter-container">
      <div className={`Filter-backdrop ${showFilters ? "open" : ""}`} onClick={toggleFilters}></div>
      <div className={`Filter-side ${showFilters ? "open" : ""}`}>
        <div className="Filter-side-header">
          <Button color="white" size="sm" onClick={toggleFilters}>
            <Icon content="close" />
          </Button>
        </div>

        <Form>
          <FormRow>
            <FormItem name="term" label="Termine">
              <input
                className={`form-control `}
                id="term"
                type="text"
                placeholder="Termine"
                {...register("term")}
              />
            </FormItem>
          </FormRow>

          <FormRow>
            <FormItem name="date" label="Data">
              <input
                className={`form-control ${errors.date ? "is-invalid" : ""}`}
                id="date"
                type="text"
                placeholder="Data"
                {...register("date")}
              />
              {errors.date && <FormError error={errors.date.message} />}
            </FormItem>
          </FormRow>

          {data?.objects && (
            <FormRow>
              <FormItem name="objects" label={"objects"}>
                <Select
                  options={createOptions(data?.objects)}
                  onSelect={(option) => onSelectChange("object", option.value)}
                  selected={getValues("object")}
                  showReset={true}
                  placeholder={"object"}
                />
              </FormItem>
            </FormRow>
          )}

          {data?.authors && (
            <FormRow>
              <FormItem name="authors" label={"authors"}>
                <Select
                  options={createOptions(data?.authors)}
                  onSelect={(option) => onSelectChange("author", option.value)}
                  selected={getValues("author")}
                  showReset={true}
                  placeholder={"author"}
                />
              </FormItem>
            </FormRow>
          )}

          {data?.locations && (
            <FormRow>
              <FormItem name="locations" label={"locations"}>
                <Select
                  options={createOptions(data?.locations)}
                  onSelect={(option) => onSelectChange("location", option.value)}
                  selected={getValues("location")}
                  showReset={true}
                  placeholder={"location"}
                />
              </FormItem>
            </FormRow>
          )}

          {data?.materials && (
            <FormRow>
              <FormItem name="materials" label={"materials"}>
                <Select
                  options={createOptions(data?.materials)}
                  onSelect={(option) => onSelectChange("material", option.value)}
                  selected={getValues("material")}
                  showReset={true}
                  placeholder={"material"}
                />
              </FormItem>
            </FormRow>
          )}

          {data?.technics && (
            <FormRow>
              <FormItem name="technics" label={"technics"}>
                <Select
                  options={createOptions(data?.technics)}
                  onSelect={(option) => onSelectChange("technic", option.value)}
                  selected={getValues("technic")}
                  showReset={true}
                  placeholder={"technic"}
                />
              </FormItem>
            </FormRow>
          )}
        </Form>

        <Button className="ml-auto submit" size="md" color="primary" onClick={handleSubmit(applyFilters)}>
          Applica filtri
        </Button>
      </div>

      {activeParams && (
        <div className="Active-filters">
          {Object.entries(activeParams).map(([key, value]) => {
            if (key !== "size" && key !== "orderBy" && key !== "order" && key !== "offset") {
              return (
                <div key={key} className="active-filter-item">
                  <span>
                    {`catalog.params[${key}]`} - {getParamValue(key, value)}
                  </span>
                  <div className="remove" onClick={() => onRemove(key)}>
                    <Icon content="close" />
                  </div>
                </div>
              );
            }
          })}
        </div>
      )}
    </div>
  );
};
