import { IAuthor } from "@/models/types";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios, { AxiosResponse } from "axios";
import { SERVER_API_URL } from "src/config/constants";
import { refreshAuthorsSearch } from "./authors-search.reducer";
import { closeModal } from "./modal-reducer";
import { getConfig } from "./config-reducer";

const INITIAL_STATE = {
  loading: false,
  errorMessage: null as unknown as string,
  fetchSuccess: false,
  fetchFailure: false,
};

export type AuthorsCrudState = Readonly<typeof INITIAL_STATE>;

export const createAuthor = createAsyncThunk(
  "authors/create",
  async (params: IAuthor, { rejectWithValue, dispatch }) => {
    try {
      const response: AxiosResponse<boolean> = await axios.post(`${SERVER_API_URL}/api/authors`, params);
      dispatch(refreshAuthorsSearch());
      dispatch(closeModal());
      dispatch(getConfig());
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const editAuthor = createAsyncThunk(
  "authors/edit",
  async (params: IAuthor, { rejectWithValue, dispatch }) => {
    try {
      const response: AxiosResponse<boolean> = await axios.put(`${SERVER_API_URL}/api/authors`, params);
      dispatch(refreshAuthorsSearch());
      dispatch(closeModal());
      dispatch(getConfig());
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const deleteAuthor = createAsyncThunk(
  "authors/delete",
  async (id: number | undefined, { rejectWithValue, dispatch }) => {
    try {
      const response: AxiosResponse<boolean> = await axios.delete(`${SERVER_API_URL}/api/authors/${id}`);
      dispatch(refreshAuthorsSearch());
      dispatch(closeModal());
      dispatch(getConfig());
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

const authorsCrudSlice = createSlice({
  name: "post",
  initialState: INITIAL_STATE,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createAuthor.pending, (state) => {
        return {
          ...state,
          loading: true,
        };
      })
      .addCase(createAuthor.fulfilled, (state) => {
        return {
          ...state,
          loading: false,
          fetchSuccess: true,
        };
      })
      .addCase(createAuthor.rejected, (state) => {
        return {
          ...state,
          loading: false,
        };
      })
      .addCase(editAuthor.pending, (state) => {
        return {
          ...state,
          loading: true,
        };
      })
      .addCase(editAuthor.fulfilled, (state) => {
        return {
          ...state,
          loading: false,
          fetchSuccess: true,
        };
      })
      .addCase(editAuthor.rejected, (state) => {
        return {
          ...state,
          loading: false,
        };
      })
      .addCase(deleteAuthor.pending, (state) => {
        return {
          ...state,
          loading: true,
        };
      })
      .addCase(deleteAuthor.fulfilled, (state) => {
        return {
          ...state,
          loading: false,
          fetchSuccess: true,
        };
      })
      .addCase(deleteAuthor.rejected, (state) => {
        return {
          ...state,
          loading: false,
        };
      });
  },
});

export default authorsCrudSlice.reducer;
