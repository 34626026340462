import { IdefaultSearchParams } from "@/models/types";

export const getQueryParamsFromUrl = (params, search) => {
  if (!search) {
    return params;
  }
  const paramsObj = {};
  const query = Object.fromEntries(new URLSearchParams(search));

  Object.entries(params).forEach(([key, val]) => {
    const value = key in query ? query[key] : val;
    paramsObj[key] = setValue(value);
  });

  return paramsObj as any;
};

const setValue = (value) => {
  if (value === "null") {
    return null;
  } else if (value === "true" || value === "false") {
    return value === "true";
  } else if (!isNaN(value)) {
    return Number(value);
  } else if (value.includes(",")) {
    return value.split(",");
  } else {
    return value;
  }
};

export const setParamsToUrl = (params, navigate) => {
  const query = new URLSearchParams(params).toString();

  if (window.location.search !== `?${query}`) {
    navigate(`${window.location.pathname}?${query}`);
  }
};

export const defaultSearchParams: IdefaultSearchParams = {
  size: 10,
  offset: 0,
  orderBy: "createdDate",
  order: "DESC",
};
