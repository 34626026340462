import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios, { AxiosResponse } from "axios";
import { SERVER_API_URL } from "src/config/constants";
import { refreshMediaSearch } from "./media-reducer";

const INITIAL_STATE = {
  loading: false,
  errorMessage: null,
  fetchSuccess: false,
  fetchFailure: false,
};

export type MediaUploadState = Readonly<typeof INITIAL_STATE>;

export const uploadMedia = createAsyncThunk(
  "media/upload-media",
  async (data: FormData, { rejectWithValue, dispatch }) => {
    try {
      const response: AxiosResponse = await axios.post(`${SERVER_API_URL}/wp/v2/media`, data);
      dispatch(refreshMediaSearch());
      return response;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

const mediaUploadSlice = createSlice({
  name: "post",
  initialState: INITIAL_STATE,
  reducers: {
    uploadMediaReset() {
      return INITIAL_STATE;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(uploadMedia.pending, (state) => {
        return {
          ...state,
          loading: true,
        };
      })
      .addCase(uploadMedia.fulfilled, (state) => {
        return {
          ...state,
          loading: false,
          fetchSuccess: true,
        };
      })
      .addCase(uploadMedia.rejected, (state) => {
        return {
          ...state,
          loading: false,
          fetchFailure: true,
        };
      });
  },
});

const { actions } = mediaUploadSlice;
export const { uploadMediaReset } = actions;

export default mediaUploadSlice.reducer;
