import { useForm } from "react-hook-form";

import { Form, FormError, FormItem, FormRow } from "../../components/form";
import { Button } from "../../components/button";
import { Loader } from "../../components/loader";
import { ILogin } from "../../models/authentication.model";
import { Heading } from "../../components/heading";
import { validationSchema } from "../../utils/validation-utils";
import { login } from "@/reducers/auth-reducer";
import { useAppSelector, useAppDispatch } from "src/config/store";
import { AuthLayout } from "@/layouts/auth-layout";

const defaultValues: ILogin = {
  username: "",
  password: "",
};

const LoginView = () => {
  const dispatch = useAppDispatch();
  const authentication = useAppSelector((state) => state.auth);

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({ mode: "onChange", defaultValues });

  const doLogin = (data: ILogin) => {
    dispatch(login(data));
  };

  return (
    <AuthLayout>
      <div className="auth-form">
        <div className="auth-form-container">
          <Form>
            <Heading content={"Accedi"} size={2} className="mb-1" />

            <FormRow>
              <FormItem name="username" label={"Email"}>
                <input
                  className={`form-control ${errors.username ? "is-invalid" : ""}`}
                  id="username"
                  placeholder={"Email"}
                  {...register("username", validationSchema.required)}
                />
                {errors.username && <FormError error={errors.username.message} />}
              </FormItem>
            </FormRow>
            <FormRow>
              <FormItem name="password" label={"Password"}>
                <input
                  type="password"
                  className={`form-control ${errors.password ? "is-invalid" : ""}`}
                  id="password"
                  placeholder={"Password"}
                  {...register("password", validationSchema.password)}
                />
                {errors.password && <FormError error={errors.password.message} />}
                <p>
                  {/* <a className="link primary font-size-sm" onClick={onModalOpen}>
                    Password dimenticata
                  </a> */}
                </p>
              </FormItem>
            </FormRow>

            <FormRow>
              <Button
                className="full-width"
                size="md"
                color="primary"
                onClick={handleSubmit(doLogin)}
                disabled={!isValid}
              >
                {!authentication.loading ? "Accedi" : <Loader />}
              </Button>
            </FormRow>
          </Form>
        </div>
      </div>
    </AuthLayout>
  );
};

export default LoginView;
