import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios, { AxiosResponse } from "axios";
import { SERVER_API_URL } from "src/config/constants";
import { IMediaItem, IMediaParams } from "src/models/media.model";

const INITIAL_STATE = {
  loading: false,
  errorMessage: null,
  fetchSuccess: false,
  fetchFailure: false,
  search: null as unknown as IMediaItem[],
  count: null as unknown as number,
  refresh: 0,
};

export type MediaState = Readonly<typeof INITIAL_STATE>;

export const getAllMedia = createAsyncThunk(
  "media/get-all-media",
  async (params: IMediaParams, { rejectWithValue }) => {
    try {
      const response: AxiosResponse<IMediaItem[]> = await axios.get(
        `${SERVER_API_URL}/wp/v2/media?page=${params.page}&per_page=${params.postPerPage}`
      );
      return response;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

const catalogSearchSlice = createSlice({
  name: "post",
  initialState: INITIAL_STATE,
  reducers: {
    refreshMediaSearch(state) {
      return {
        ...state,
        refresh: state.refresh + 1,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllMedia.pending, (state) => {
        return {
          ...state,
          loading: true,
        };
      })
      .addCase(getAllMedia.fulfilled, (state, { payload }) => {
        return {
          ...state,
          loading: false,
          fetchSuccess: true,
          search: payload.data,
          count: Number(payload.headers["x-wp-total"]),
        };
      })
      .addCase(getAllMedia.rejected, (state) => {
        return {
          ...state,
          loading: false,
          fetchFailure: true,
        };
      });
  },
});

const { actions } = catalogSearchSlice;
export const { refreshMediaSearch } = actions;
export default catalogSearchSlice.reducer;
