import { TMediaItem } from "./media.model";
import { useAppDispatch } from "src/config/store";
import { openModal } from "@/reducers/modal-reducer";
import MediaDetail from "./media-detail";

type TMediaProps = {
  media: TMediaItem;
};

export const MediaCard = (props: TMediaProps) => {
  const { media } = props;

  const dispatch = useAppDispatch();

  const image = { backgroundImage: `url( ${media.source_url} )` };

  const handleMedia = () => {
    dispatch(
      openModal({
        element: <MediaDetail media={media} />,
        opt: { size: "md", title: "Dettaglio media" },
      })
    );
  };

  return (
    <div className={`media-item`} onClick={handleMedia}>
      <div className="cover" style={image} />
      <span> {media.media_details.original_image || media.slug} </span>
    </div>
  );
};
