import { BrowserRouter, Route, Routes } from "react-router-dom";

import { Home } from "@/views/home/";
import { Login } from "./views/login";
import { NotFound } from "./views/not-found/not-found";
import { CatalogSearch } from "./views/catalog/search";
import { CatalogEdit } from "./views/catalog/edit";
import { CatalogView } from "./views/catalog/view";
import { AuthorsSearch } from "./views/authors/search";
import { ObjectsSearch } from "./views/objects/search";
import { LocationsSearch } from "./views/locations/search";
import { MaterialsSearch } from "./views/materials/search";
import { TechnicsSearch } from "./views/technics/search";
import { Media } from "./views/media";

const AppRoutes = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/catalog">
          <Route path="/catalog" element={<CatalogSearch />} />
          <Route path="/catalog/new" element={<CatalogEdit />} />
          <Route path="/catalog/:id/edit" element={<CatalogEdit />} />
          <Route path="/catalog/:id/view" element={<CatalogView />} />
        </Route>

        <Route path="/authors" element={<AuthorsSearch />} />
        <Route path="/objects" element={<ObjectsSearch />} />
        <Route path="/locations" element={<LocationsSearch />} />
        <Route path="/materials" element={<MaterialsSearch />} />
        <Route path="/technics" element={<TechnicsSearch />} />
        <Route path="/media" element={<Media />} />

        {/* 
  
        <Route path="/media" element={Media} />  */}

        <Route path="/login" element={<Login />} />
        {/* <Route path="/account/activate/:key?" element={Activate} />
        <Route path="/account/reset/request" element={PasswordResetInit} />
        <Route path="/account/reset/finish/:key?" element={PasswordResetFinish} />
        <Route path="/account" element={Account} /> */}
        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
};

export default AppRoutes;
