import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios, { AxiosResponse } from "axios";
import { SERVER_API_URL } from "src/config/constants";
import { ILogin } from "src/models/authentication.model";
import { IAccount } from "src/models/user.model";

const INITIAL_STATE = {
  loading: false,
  isAuthenticated: false,
  loginSuccess: false,
  loginError: false, // Errors returned from server side
  account: {} as IAccount,
  errorMessage: null as unknown as string, // Errors returned from server side
  redirectMessage: null as unknown as string,
  sessionHasBeenFetched: false,
};

export type AuthState = Readonly<typeof INITIAL_STATE>;

export const login = createAsyncThunk("auth/login", async (data: ILogin, { rejectWithValue, dispatch }) => {
  try {
    const response = await axios.post(`${SERVER_API_URL}/jwt-auth/v1/token`, data);
    localStorage.setItem("token", response.data.data.token);
    dispatch(getSession());
    return response.data;
  } catch (error: any) {
    return rejectWithValue(error.message);
  }
});

export const getSession = createAsyncThunk("auth/session", async (data, { rejectWithValue }) => {
  try {
    const response: AxiosResponse<IAccount> = await axios.get(`${SERVER_API_URL}/api/account`);
    return response.data;
  } catch (error: any) {
    return rejectWithValue(error.message);
  }
});

const authSlice = createSlice({
  name: "post",
  initialState: INITIAL_STATE,
  reducers: {
    logout() {
      localStorage.removeItem("token");
      return INITIAL_STATE;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        return {
          ...state,
          loading: true,
        };
      })
      .addCase(login.fulfilled, (state) => {
        return {
          ...state,
          loading: false,
          loginSuccess: true,
        };
      })
      .addCase(login.rejected, (state) => {
        return {
          ...state,
          loading: false,
          loginError: true,
        };
      })

      .addCase(getSession.pending, (state) => {
        return {
          ...state,
          loading: true,
        };
      })
      .addCase(getSession.fulfilled, (state, { payload }) => {
        return {
          ...state,
          loading: false,
          isAuthenticated: true,
          account: payload,
          sessionHasBeenFetched: true,
        };
      })
      .addCase(getSession.rejected, (state) => {
        return {
          ...state,
          loading: false,
          isAuthenticated: false,
          sessionHasBeenFetched: true,
        };
      });
  },
});

const { actions } = authSlice;
export const { logout } = actions;

export default authSlice.reducer;
