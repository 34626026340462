import { useState, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "src/config/store";
import { getAllMedia } from "@/reducers/media-reducer";
import { Loader } from "../loader";
import { Icon } from "../icon";
import { defaultMediaParams } from "src/utils/media-utils";
import { IMediaParams, TSelectMode } from "src/models/media.model";

type Props = {
  onSelectImage: (id: number[]) => void;
  selectMode: TSelectMode;
};

const MediaList = (props: Props) => {
  const { onSelectImage, selectMode } = props;

  const dispatch = useAppDispatch();
  const mediaState = useAppSelector((state) => state.media);

  const [params, setParams] = useState<IMediaParams>(defaultMediaParams);

  const [selected, setSelected] = useState<number[]>([]);

  const onSelect = (id: number) => {
    if (selectMode === "SINGLE" && selected.length === 1) {
      if (selected[0] === id) {
        setSelected([]);
      } else {
        setSelected([id]);
      }
    } else {
      if (selected.includes(id)) {
        setSelected(selected.filter((item) => item !== id));
      } else {
        setSelected([...selected, id]);
      }
    }
  };

  useEffect(() => {
    dispatch(getAllMedia(params));
  }, []);

  return (
    <>
      {mediaState.loading && <Loader color="#000000" />}
      {!mediaState.loading && mediaState.search?.length === 0 && "Nessun media"}

      <div className="media-grid">
        {mediaState.search?.map((media) => {
          const image = { backgroundImage: `url( ${media.source_url} )` };
          const isSelected = selected.includes(media.id);

          return (
            <div
              key={media.id}
              className={`media-item ${isSelected ? "selected" : ""}`}
              onClick={() => onSelect(media.id)}
            >
              <div className="cover" style={image}></div>{" "}
              <div className="dot">
                <Icon content="checked" />
              </div>
            </div>
          );
        })}
      </div>

      {selected.length > 0 && (
        <button className="mediaManage-button" onClick={() => onSelectImage(selected)}>
          Seleziona
        </button>
      )}
    </>
  );
};

export default MediaList;
