import styles from "./modal.module.scss";
import { closeModal } from "@/reducers/modal-reducer";
import { useAppDispatch } from "src/config/store";

const ModalBackdrop = () => {
  const dispatch = useAppDispatch();

  return <div className={styles.backDrop} onClick={() => dispatch(closeModal())} />;
};

export default ModalBackdrop;

ModalBackdrop.displayName = "Modal.Backdrop";
