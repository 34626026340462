import { Heading } from "@/components/heading";
import { Loader } from "@/components/loader";
import { PrivateRoute } from "@/layouts/private-route";
import { getCatalog } from "@/reducers/catalog-search-reducer";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "src/config/store";
import { Swiper, SwiperSlide } from "swiper/react";

export const Home = () => {
  const { id } = useAppSelector((state) => state.auth.account);
  const catalogState = useAppSelector((state) => state.catalogSearch);

  const dispatch = useAppDispatch();
  const params = {
    modifiedBy: id,
    offset: 0,
    size: 5,
  };

  useEffect(() => {
    // dispatch(getCatalog(params));
  }, []);

  return (
    <PrivateRoute>
      <>
        <div className="my-catalog">
          <Heading size={2} content="Ultime modifiche" className="mb-2" />

          {catalogState.loading && <Loader />}
          {!catalogState.loading && catalogState.search?.list?.length === 0 && "Non ci sono risultati"}
          {!catalogState.loading && catalogState.search?.list?.length > 0 && (
            <div className="my-catalog-slider">
              <Swiper slidesPerView={"auto"} style={{ marginLeft: 0, marginRight: 0, width: "100%" }}>
                {catalogState.search?.list.map((item) => {
                  const image = { backgroundImage: `url(${item.featured_media})` };
                  return (
                    <SwiperSlide className="my-catalog-slide" key={item.ID}>
                      <div className="cover" style={image} />
                      <div className="meta">
                        <Link to={`/catalog/${item.ID}/view`}>
                          <Heading size={12} content={item.post_title} />
                        </Link>
                      </div>
                    </SwiperSlide>
                  );
                })}
              </Swiper>
            </div>
          )}
        </div>
      </>
    </PrivateRoute>
  );
};
