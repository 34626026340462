import "./button.scss";

type ButtonProps = {
  children: any;
  className?: string;
  onClick?: () => void;
  disabled?: boolean;
  size?: "sm" | "md" | "lg" | "xl";
  color?: "primary" | "white";
};

export const Button = (props: ButtonProps) => {
  const { children, onClick, className, disabled, size, color } = props;

  return (
    <button
      className={`button ${size || ""} ${color || ""} ${className || ""}`}
      disabled={disabled}
      onClick={onClick}
    >
      {children}
    </button>
  );
};
