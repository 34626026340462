import { PrivateRoute } from "@/layouts/private-route";
import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import {
  createOptions,
  defaultAuthor,
  defaultCatalogItem,
  defaultLocation,
  defaultMaterial,
  defaultObject,
  defaultTechnic,
} from "src/utils/utils";
import { Form, FormError, FormGroup, FormGroupTitle, FormItem, FormRow } from "@/components/form";
import { Flex } from "@/components/flex";
import { Button } from "@/components/button";
import { Icon } from "@/components/icon";
import { validationSchema } from "src/utils/validation-utils";
import { Select } from "@/components/select";
import { Loader } from "@/components/loader";
import { useAppDispatch, useAppSelector } from "src/config/store";
import { FeaturedImage } from "../featured-image";
import { createCatalogItem, editCatalogItem } from "@/reducers/catalog-crud.reducer";
import { openModal } from "@/reducers/modal-reducer";
import { useEffect, useState } from "react";
import { Notes } from "./notes";
import { ICatalogItem } from "@/models/catalog.model";
import { MediaContainer } from "./media-container";
import { clearCatalogItem, getCatalogItem, setCatalogItem } from "@/reducers/catalog-item-reducer";
import ObjectEdit from "@/views/objects/search/object-edit";
import AuthorsEdit from "@/views/authors/search/authors-edit";
import LocationsEdit from "@/views/locations/search/locations-edit";
import { MaterialsEdit } from "@/views/materials/search/materials-edit";
import { TechnicsEdit } from "@/views/technics/search/technics-edit";
import { Bibliography } from "./bibliography";

export const CatalogEdit = () => {
  const { id }: any = useParams();

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const catalogCrud = useAppSelector((state) => state.catalogCrud);
  const catalogItem = useAppSelector((state) => state.catalogItem);
  const appData = useAppSelector((state) => state.config.data);

  const isEdit = id ? true : false;

  const [notes, setNotes] = useState([]);
  const [bibliography, setBibliography] = useState([]);
  const [gallery, setGallery] = useState([]);
  const [documents, setDocuments] = useState([]);

  const onAddObject = () => {
    dispatch(
      openModal({
        element: <ObjectEdit object={defaultObject} />,
        opt: { size: "xs", title: "Nuovo oggetto" },
      })
    );
  };
  const onAddAuthor = () => {
    dispatch(
      openModal({
        element: <AuthorsEdit author={defaultAuthor} />,
        opt: { size: "xs", title: "Nuovo autore" },
      })
    );
  };

  const onAddLocation = () => {
    dispatch(
      openModal({
        element: <LocationsEdit location={defaultLocation} />,
        opt: { size: "xs", title: "Nuova collocazione" },
      })
    );
  };

  const onAddMaterial = () => {
    dispatch(
      openModal({
        element: <MaterialsEdit material={defaultMaterial} />,
        opt: { size: "xs", title: "Nuovo materiale" },
      })
    );
  };

  const onAddTecnich = () => {
    dispatch(
      openModal({
        element: <TechnicsEdit technic={defaultTechnic} />,
        opt: { size: "xs", title: "Nuova tecnica" },
      })
    );
  };

  const handleMetaModal = () => {
    // dispatch(openModal())
  };

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    reset,
    formState: { errors, isValid },
  } = useForm({ mode: "onChange", defaultValues: defaultCatalogItem });

  const onSelectChange = (key, value) => {
    setValue(key, value, { shouldValidate: true });
  };

  const onUpdateMedia = (mediaId?: number) => {
    setValue("featured_media", mediaId || null);
  };

  const onContentChange = (event) => {
    setValue("content", event.target.innerHTML);
  };

  const onUpdateNotes = (data) => {
    setNotes(data);
  };

  const onUpdatebibliography = (data) => {
    setBibliography(data);
  };
  const onUpdateGallery = (data) => {
    setGallery(data);
  };

  const onUpdateDocuments = (data) => {
    setDocuments(data);
  };

  const save = (data: ICatalogItem) => {
    const catalogItem = {
      ...data,
      notes: JSON.stringify(notes),
      bibliography: JSON.stringify(bibliography),
      gallery: JSON.stringify(gallery),
      documents: JSON.stringify(documents),
    };

    isEdit
      ? dispatch(editCatalogItem(catalogItem))
      : dispatch(createCatalogItem({ params: catalogItem, navigate }));
  };

  useEffect(() => {
    if (id) {
      dispatch(getCatalogItem(id));
    }
    return () => {
      dispatch(clearCatalogItem());
    };
  }, []);

  useEffect(() => {
    if (catalogItem.item) {
      reset(catalogItem.item);
      setNotes(catalogItem.item.notes ? JSON.parse(catalogItem.item.notes) : []);
      setBibliography(catalogItem.item.bibliography ? JSON.parse(catalogItem.item.bibliography) : []);
      setGallery(catalogItem.item.gallery ? JSON.parse(catalogItem.item.gallery) : []);
      setDocuments(catalogItem.item.documents ? JSON.parse(catalogItem.item.documents) : []);
    } else {
      dispatch(setCatalogItem(defaultCatalogItem));
    }
  }, [catalogItem.item]);

  return (
    <PrivateRoute>
      <>
        {catalogItem.loading && <Loader color="#000000" />}
        {catalogItem.item && (
          <>
            <div className="catalog-detail">
              <div className="col-left">
                <FeaturedImage catalogItem={catalogItem.item} onUpdateMedia={onUpdateMedia} />
              </div>

              <Form className="col-right">
                {isEdit && (
                  <Flex container justifyContent="flex-end">
                    <Button onClick={handleMetaModal}>
                      <Icon content="info" />
                    </Button>
                  </Flex>
                )}

                <FormGroup>
                  <FormGroupTitle title={"Informazioni generali"} />

                  <FormRow>
                    <FormItem name="post_title" label={"Titolo"}>
                      <input
                        id="post_title"
                        placeholder={"Titolo"}
                        {...register("post_title", validationSchema.required)}
                      />

                      {errors.post_title && <FormError error={errors.post_title.message} />}
                    </FormItem>
                  </FormRow>

                  <FormRow>
                    <FormItem name="objects" label={"Oggetto"}>
                      {appData?.objects && (
                        <Select
                          options={createOptions(appData?.objects)}
                          onSelect={(option) => onSelectChange("object", option.value)}
                          selected={Number(getValues("object")) || ""}
                          placeholder={"Oggetto"}
                          showSearch={true}
                          addCallback={onAddObject}
                        />
                      )}
                    </FormItem>
                  </FormRow>

                  <FormRow>
                    <FormItem name="authors" label={"Autore"}>
                      {appData?.authors && (
                        <Select
                          options={createOptions(appData?.authors)}
                          onSelect={(option) => onSelectChange("author", option.value)}
                          selected={Number(getValues("author")) || ""}
                          showReset={true}
                          placeholder={"Autore"}
                          showSearch={true}
                          addCallback={onAddAuthor}
                        />
                      )}
                    </FormItem>
                  </FormRow>

                  <FormRow>
                    <FormItem name="locations" label={"Collocazione"}>
                      {appData?.locations && (
                        <Select
                          options={createOptions(appData?.locations)}
                          onSelect={(option) => onSelectChange("location", option.value)}
                          selected={Number(getValues("location")) || ""}
                          placeholder={"Collocazione"}
                          showSearch={true}
                          addCallback={onAddLocation}
                        />
                      )}
                    </FormItem>
                  </FormRow>

                  <FormRow>
                    <FormItem name="date" label={"Datazione"}>
                      <input
                        id="date"
                        placeholder={"Datazione"}
                        {...register("date", validationSchema.date)}
                      />

                      {errors.date && <FormError error={errors.date.message} />}
                    </FormItem>
                  </FormRow>
                </FormGroup>

                <FormGroup>
                  <FormGroupTitle title={"Informazioni tecniche"} />

                  <FormRow>
                    <FormItem name="materials" label={"Materiali"}>
                      {appData?.materials && (
                        <Select
                          options={createOptions(appData?.materials)}
                          onSelect={(option) => onSelectChange("material", option.value)}
                          selected={Number(getValues("material")) || ""}
                          placeholder={"Materiali"}
                          showSearch={true}
                          addCallback={onAddMaterial}
                        />
                      )}
                    </FormItem>
                  </FormRow>

                  <FormRow>
                    <FormItem name="technics" label={"Tecnica"}>
                      {appData?.technics && (
                        <Select
                          options={createOptions(appData?.technics)}
                          onSelect={(option) => onSelectChange("technic", option.value)}
                          selected={Number(getValues("technic")) || ""}
                          placeholder={"Tecnica"}
                          showSearch={true}
                          addCallback={onAddTecnich}
                        />
                      )}
                    </FormItem>
                  </FormRow>

                  <FormRow>
                    <FormItem name="measures" label={"Misura"}>
                      <input id="measures" placeholder={"Misura"} {...register("measures")} />

                      {errors.measures && <FormError error={errors.measures.message} />}
                    </FormItem>
                  </FormRow>
                </FormGroup>

                <FormGroup>
                  <FormGroupTitle title={"Informazioni storico critiche"} />
                  <FormRow>
                    <FormItem name="content" label={"Contenuto"}>
                      <div
                        className="textarea content-textarea"
                        onInput={onContentChange}
                        contentEditable
                        placeholder="Inserisci contenuto..."
                        suppressContentEditableWarning={true}
                        dangerouslySetInnerHTML={{ __html: getValues("content") }}
                      />
                    </FormItem>
                  </FormRow>
                </FormGroup>

                <Notes notes={notes} onUpdateNotes={onUpdateNotes} />
                <Bibliography biographies={bibliography} onUpdatebibliography={onUpdatebibliography} />

                <MediaContainer
                  title="Galleria"
                  mediaType="IMAGE"
                  data={gallery}
                  onUpdate={onUpdateGallery}
                />
                <MediaContainer
                  title="Documenti"
                  mediaType="DOCUMENT"
                  data={documents}
                  onUpdate={onUpdateDocuments}
                />

                <FormRow>
                  <FormItem>
                    <Flex container alignItems="center">
                      <Button
                        className="ml-auto"
                        size="md"
                        color="primary"
                        disabled={!isValid || catalogCrud.loading}
                        onClick={handleSubmit(save)}
                      >
                        {catalogCrud.loading ? <Loader /> : "Salva"}
                      </Button>
                    </Flex>
                  </FormItem>
                </FormRow>
              </Form>
            </div>
          </>
        )}
      </>
    </PrivateRoute>
  );
};
